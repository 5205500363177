export const SI_INTEGRATION_CODES = {
    INTTRA: "INTTRA"
}

export const CONTAINER_TRACKING_INTEGRATION_CODES = {
    HL: "HL"
}

export const SI_TEST_USERS_IDS = [
    4060,
    4062,
    4056,
    4078
]

export const HL_SHIPMENTLINE_ID = 49589

export const TASK_TYPES = {
    FORM_DOCUMENT: 5
}

export const TASK_STATUS = {
    PENDING: "Pending"
}

export const INTEGRATION_CODES = {
    INTTRA_SI_CREATE: "GLOBAL_Inttra_SI_create"
}

export const SI_FORM_ACTION_TYPES = {
    EMIT: 1,
    INTERNAL: 2
}

export const ALLOWED_STATUSES=['Confirmed','Stuffed','Sent']