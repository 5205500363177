const steps = [
  {
    element: "#task-category",
    popover: {
      title: "Task - Category",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-int-code",
    popover: {
      title: "Task - Integration Code",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-reference",
    popover: {
      title: "Task - Reference",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-linked",
    popover: {
      title: "Task - Linked",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-sub",
    popover: {
      title: "Task - Subtasks",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-shipper",
    popover: {
      title: "Task - Shipper Info",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-consignee",
    popover: {
      title: "Task - Consignee Info",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-form",
    popover: {
      title: "Task - Form",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-attachments",
    popover: {
      title: "Task - Attachments",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-complete-action",
    popover: {
      title: "Task - Complete Action",
      description:
        "Once you are finished, press this button to complete this action",
      position: "right",
    },
  },
  {
    element: "#task-start",
    popover: {
      title: "Task - Start",
      description: "Upon pressing this button, the designated task starts",
      position: "right",
    },
  },
  {
    element: "#task-download-request",
    popover: {
      title: "Task - Download Request",
      description: "",
      position: "right",
    },
  },
  {
    element: "#task-download-response",
    popover: {
      title: "Task - Download Response",
      description: "",
      position: "right",
    },
  },
];

export default steps;
