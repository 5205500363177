<template>
  <div>
    <v-card v-if="task && task.taskType">
      <v-toolbar color="transparent" flat>
        <v-btn icon v-if="task && task.isChild" @click="back()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          <v-tooltip top :key="statusKey">
            <template v-slot:activator="{ on }">
              <v-icon class="mr-2" v-on="on" :color="getTaskStatus(task).color">{{ getTaskStatus(task).icon }}</v-icon>
            </template>
            <span>{{ getTaskStatus(task).name }}</span> </v-tooltip>{{ task.id }}: {{ task.title }} {{ fileNumber ?
              '(' + fileNumber.slice(-6) + ')' : null }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <!-- <span style="color: grey; margin-right: 10px;"
          v-if="task.title === 'PPECB Addendum : Titan Addendum' || task.title === 'Validate Titan'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="downloadJSON(task)">download</v-icon>
            </template>
            <span>Download JSON</span>
          </v-tooltip>
        </span> -->


        <v-btn id="info-guide" @click="infoStartGuide" icon>
          <v-icon>help</v-icon>
        </v-btn>
        <v-btn id="task-complete-action" small color="primary" v-if="actionRequired()" @click="completeForm">Complete
          Action</v-btn>
        <span style="font-size: 12px" v-else-if="documentsRequired()"><v-icon small color="orange">warning</v-icon>
          Documents
          Required</span>
        <v-btn id="task-start" v-else-if="shipment.documentationStatus == 'OPEN' && task.status == 'Not started'"
          color="primary" text @click="checkParentDoc(task)" :loading="startingTask">Start Task</v-btn>
          <v-tooltip top v-if="task.requestData">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" id="task-download-request"  icon
          @click="downloadRequest"><v-icon>downloading</v-icon></v-btn>
            </template>
            <span>Download Request JSON</span>
          </v-tooltip>
          <v-tooltip top v-if="task.responseData">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" id="task-download-response"  icon
          @click="downloadResponse"><v-icon>download</v-icon></v-btn>
            </template>
            <span>Download Response JSON</span>
          </v-tooltip>
       
       
        <a id="downloadAnchorElem" style="display:none"></a>
        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-card-text class="mt-0 pt-0">
        <v-tabs v-model="selectedTab" show-arrows>
          <v-tab key="info" href="#info">Info <v-icon right>info</v-icon></v-tab>
          <v-tab key="inspection" href="#inspection" v-if="task.inspectionCode">Inspection <v-icon
              right>rule</v-icon></v-tab>
          <v-tab key="log" href="#log">Log <v-icon right>history</v-icon></v-tab>
          <v-tab key="payload" href="#payload" v-if="task.requestData">Request Payload <v-icon
              right>data_object</v-icon></v-tab>
          <v-tab key="response" href="#response" v-if="task.responseData">Response <v-icon
              right>data_object</v-icon></v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item value="info" style="background: var(--v-modal-base) !important">
            <v-card flat>
              <v-card-text :key="actionKey">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-list :key="actionKey">
                      <p style="color: grey">{{ task.description }}</p>

                      <v-list-item id="task-category">
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="secondary">category</v-icon>
                            </template>
                            <span>Task Category</span>
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ task.taskType.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Task Category
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="task.integrationCode" id="task-int-code">
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="secondary">webhook</v-icon>
                            </template>
                            <span>Integration</span>
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ task.integrationCode }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Integration Code
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="task.reference" id="task-reference">
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="secondary">tag</v-icon>
                            </template>
                            <span>Task Reference Result</span>
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ task.reference }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Reference
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-else-if="task.resultReference" id="task-reference">
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="secondary">tag</v-icon>
                            </template>
                            <span>Task Reference Result</span>
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ task.resultReference }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Reference
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- <v-list-item v-if="task.reference && ($store.state.user.tagId === 1 || $store.state.user.tagId === 2) && task.status !== 'Complete'">
                        <v-list-item-action>
                          <v-switch
                            v-model="task.poll"
                            color="primary"
                            hide-details
                            @change="updateTaskPoll"
                          ></v-switch>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            Task Polling
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item> -->

                      <v-list dense subheader id="task-linked">
                        <v-list-item style="height: 25px" v-if="task.bookingContainer">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              task.bookingContainer.containerNo
                            }} - {{ task.bookingContainer.ctoNo }}</v-list-item-title>
                            <v-list-item-subtitle>Linked Container</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list dense subheader v-else-if="documentContainer.length > 0"
                          style="max-height: 30vh; overflow-y: auto">
                          <v-subheader style="font-size: 16px">Linked Container(s)</v-subheader>
                          <v-list-item v-for="container in documentContainer" :key="container.id">
                            <v-list-item-content>
                              <v-list-item-title>{{
                                container.containerNo
                              }} - {{ container.ctoNo }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                container.sealNo
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list>

                      <v-list dense v-if="task.childTasks && task.childTasks.length > 0" id="task-sub">
                        <v-subheader style="font-size: 16px">Subtasks</v-subheader>
                        <v-list-item @click="viewSubtask(subtask)" v-for="subtask in task.childTasks" :key="subtask.id">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on" :color="getTaskStatus(subtask).color">{{
                                    getTaskStatus(subtask).icon
                                  }}</v-icon>
                                </template>
                                <span>{{ getTaskStatus(subtask).name }}</span>
                              </v-tooltip>
                              {{ subtask.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ subtask.description }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            <div v-if="subtask.passed != null">
                              <v-icon
                                color="success"
                                small
                                v-if="subtask.passed"
                                >check</v-icon
                              >
                              <v-icon color="red" small v-else>close</v-icon>
                            </div>
                          </v-list-item-action> -->
                        </v-list-item>
                      </v-list>
                    </v-list>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-- <v-list id="task-shipper" dense v-if="shipmentDocumentItem && shipment && shipment.onBehalfShipper"
                      class="my-0 py-0">
                      <v-select class="my-0 py-0" label="Shipper Document Address"
                        :disabled="shipment.documentationStatus == 'CLOSED'" v-model="shipmentDocumentItem.shipperAddress"
                        item-value="value" item-text="name" :items="availableShipperAddresses" outlined
                        @change="updateDocumentType" dense></v-select>
                      <v-checkbox class="my-0 py-0" :disabled="shipment.documentationStatus == 'CLOSED'"
                        label="Display on behalf shipper name" v-model="shipmentDocumentItem.displayOnBehalfShipperName"
                        @change="updateDocumentType"></v-checkbox>
                    </v-list>
                    <v-list id="task-consignee" dense class="my-0 py-0"
                      v-if="shipmentDocumentItem && shipment && shipment.onBehalfConsignee">
                      <v-select class="my-0 py-0" label="Consignee Document Address"
                        :disabled="shipment.documentationStatus == 'CLOSED'"
                        v-model="shipmentDocumentItem.consigneeAddress" item-value="value" item-text="name"
                        :items="availableConsigneeAddresses" @change="updateDocumentType" outlined dense></v-select>
                      <v-checkbox class="my-0 py-0" :disabled="shipment.documentationStatus == 'CLOSED'"
                        label="Display on behalf consignee name"
                        v-model="shipmentDocumentItem.displayOnBehalfConsigneeName"
                        @change="updateDocumentType"></v-checkbox>
                    </v-list> -->

                    <v-list dense :key="actionKey" id="task-form">
                      <v-subheader v-if="actionAvailable" style="font-size: 16px">Task Form</v-subheader>
                      <v-list-item v-if="actionAvailable" @click="completeForm">
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" v-if="actionAvailable.status == 'Pending'"
                                color="orange">schedule</v-icon>
                              <v-icon v-bind="attrs" v-on="on" v-else-if="actionAvailable.status == 'In Progress'
                                " color="blue">pending</v-icon>
                              <v-icon v-bind="attrs" v-on="on" v-else-if="actionAvailable.status == 'Complete'"
                                color="green">task_alt</v-icon>
                            </template>
                            <span style="font-size: 12px">{{
                              actionAvailable.status
                            }}</span>
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ actionAvailable.form.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ actionAvailable.locationDocument.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="actionAvailable.user && actionAvailable.status == 'Complete'">
                            Submitted by {{ actionAvailable.user.firstname }}
                            {{ actionAvailable.user.surname }} on
                            {{ formatDate(actionAvailable.updatedAt) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-progress-circular v-if="loadingForm" indeterminate color="primary" size="24"></v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list dense :key="taskActionKey">
                        <v-subheader style="font-size: 16px">Task
                          Actions</v-subheader>
                        <v-progress-linear indeterminate color="primary" v-if="loadingTaskActions"></v-progress-linear>
                        <v-list-item
                          :disabled="shipment.documentationStatus == 'CLOSED' || !action.statuses.includes(task.status)"
                          @click="action.confirmRequired?confirmAction(action):executeAction(action)" v-for="action in availableActions(task)" :key="action.code">
                          <v-list-item-action>
                            <v-icon :color="action.color">{{
                              action.icon
                            }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ action.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-wrap">
                              {{ action.description }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-progress-circular size="24" v-show="loadingAction && loadingAction == action.code
                              " indeterminate color="primary"></v-progress-circular>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="!loadingTaskActions && availableActions(task).length == 0">
                          <v-list-item-content class="text-center">
                            <span style="color: grey">No actions available</span>
                          </v-list-item-content></v-list-item>
                      </v-list>
                      <!-- <v-list v-if=" task.title == 'PPECB Addendum : Titan Addendum' || 'Validate Titan'">
                        <v-list-item content class="text-center">
                          <span style="color: grey">
                            <v-btn color="primary" @click="downloadJSON(task)">
                              <v-icon small>download</v-icon> Download JSON
                            </v-btn>
                          </span>
                          </v-list-item>
                      </v-list> -->
                    </v-list>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" id="task-attachments">
                    <v-subheader style="font-size: 16px">Task Attachments
                      <v-btn :key="attachmentKey" @click="addAttachmentModal = true" icon v-if="shipment.documentationStatus == 'OPEN' && taskAttachments.filter((x) => !x.file).length > 0
                        " color="primary"><v-icon>add_circle_outline</v-icon></v-btn>
                    </v-subheader>
                    <v-progress-linear color="primary" indeterminate v-if="loadingAttachments"></v-progress-linear>
                    <v-list dense :key="attachmentKey" style="max-height: 45vh; min-height: 10vh; overflow-y: auto;">
                      <v-list-item v-for="doc in taskAttachments.filter(
                        (x) => x.file
                      )" :key="doc.id">
                        <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                          <v-icon v-if="doc.required && !doc.file" color="orange">warning</v-icon>
                          <v-icon v-else-if="doc.required && doc.file" color="green">check</v-icon>
                          <v-img v-else-if="getDocumentIcon(doc.name)" class="mr-2" style="height: 30px; width: 30px"
                            :src="getDocumentIcon(doc.name)">
                          </v-img>
                          <v-icon v-else-if="doc.file" color="green">check</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ doc.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="doc.file">
                            {{ formatDate(doc.createdAt) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else>
                            <i v-if="doc.required">Required</i>
                            <i v-else>Not Required</i>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action :key="documentKey">
                          <v-row :key="documentKey">
                            <v-btn :key="documentKey" :loading="docLoading == doc.id" icon
                              v-if="doc.file && (doc.file.toLowerCase()).includes('.pdf')" small
                              @click="previewDocument(doc)"><v-icon small>visibility</v-icon></v-btn>
                            <v-btn icon v-if="shipment.documentationStatus == 'OPEN' && doc.file && doc.code" small
                              @click="attachDocument(doc)"><v-icon small>edit</v-icon></v-btn>
                            <v-btn v-if="doc.file" icon :loading="doc.loading" @click="downloadDocument(doc)"
                              small><v-icon small>download</v-icon></v-btn>
                            <v-btn v-else-if="shipment.documentationStatus == 'OPEN'" small icon
                              @click="attachDocument(doc)"><v-icon small>add</v-icon></v-btn>
                            <v-btn color="red" v-if="shipment.documentationStatus == 'OPEN'" small icon
                              @click="deleteAttachment(doc)"><v-icon small>delete</v-icon></v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="taskAttachments.filter((x) => x.file).length == 0
                        ">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No attachments.</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="inspection" style="background: var(--v-modal-base) !important">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-subheader style="font-size: 16px" v-if="task.inspectionCode">Inspection Actions
                    </v-subheader>
                    <v-list v-if="task.inspectionCode" dense :key="inspectionTaskKey">
                      <!-- Validation -->
                      <v-list-item style="height:50px">
                        <v-list-item-action :key="loadingKey" class="text-center">
                          <v-chip v-if="!validating && !task.inspectionValidated">1</v-chip>
                          <v-progress-circular color="primary" size="18" indeterminate
                            v-else-if="validating"></v-progress-circular>
                          <v-icon color="green"
                            v-else-if="task.inspectionValidated && task.inspectionValidationPassed">task_alt</v-icon>
                          <v-icon color="red"
                            v-else-if="task.inspectionValidated && !task.inspectionValidationPassed">cancel</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="!validating">
                            <span v-if="!task.inspectionValidationPassed">
                              Submit for Validation</span>
                            <span v-else>Data Validated</span>
                          </v-list-item-title>
                          <v-list-item-title v-else>
                            Validating...
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="!validating">
                          <v-row justify="center">
                            <v-tooltip top v-if="!validating && shipment.documentationStatus == 'OPEN'">
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" color="blue" @click="validateTask(task)">
                                  <v-icon>play_circle</v-icon></v-btn>
                              </template>
                              <span>Validate the document against the inspection rules</span>
                            </v-tooltip>

                            <v-tooltip top
                              v-if="(!validating && !task.inspectionValidationPassed) && task.validationResult">
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="validationModal = true"> <v-icon>launch</v-icon></v-btn>
                              </template>
                              <span>View the validation errors</span>
                            </v-tooltip>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item  style="height:50px"
                        v-if="!task.inspectionTaskId && titanStatus == false"
                        :disabled="shipment.documentationStatus == 'CLOSED' || !task.inspectionValidated || !task.inspectionValidationPassed || task.inspectionTaskId"
                        @click="submitTaskForInspectionModal = true">
                        <v-list-item-action class="text-center">
                          <v-progress-circular color="primary" size="18" indeterminate
                            v-if="submittingInspection"></v-progress-circular>
                          <v-chip v-else-if="!submittingInspection">2</v-chip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Submit for Inspection
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item  style="height:50px"
                        v-if="!task.inspectionTaskId && titanStatus == true"
                        :disabled="shipment.documentationStatus == 'CLOSED' || !task.inspectionValidated || !task.inspectionValidationPassed || task.inspectionTaskId"
                        @click="addServiceFailure">
                        <v-list-item-action class="text-center">
                          <v-progress-circular color="primary" size="18" indeterminate
                            v-if="submittingInspection"></v-progress-circular>
                          <v-chip v-else-if="!submittingInspection">2</v-chip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Submit for Inspection
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item  v-if="task.inspectionTaskId && task.inspectionTask">
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" :color="getTaskStatus(task.inspectionTask).color">{{
                                getTaskStatus(task.inspectionTask).icon
                              }}</v-icon>
                            </template>
                            <span>{{ getTaskStatus(task.inspectionTask).name }}</span>
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ task.inspectionTask.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="task.inspectionTask.submittedForInspectionBy">
                            <v-icon small class="mr-1">person</v-icon>Submitted By: {{
                              task.inspectionTask.submittedForInspectionBy.firstName
                            }} {{
  task.inspectionTask.submittedForInspectionBy.surname
}}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="task.inspectionTask">
                            <v-icon small class="mr-1">today</v-icon>Submitted: {{
                              formatDate(task.inspectionTask.createdAt)
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="task.inspectionTask.inspectedBy">
                            <v-icon small class="mr-1">person</v-icon>Submitted By: {{
                              task.inspectionTask.inspectedBy.firstName
                            }} {{
  task.inspectionTask.inspectedBy.surname
}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if="task.inspectionTask">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon
                                @click="viewInspection(task.inspectionTask)"><v-icon>launch</v-icon></v-btn>
                            </template>
                            <span>View Inspection</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item @click="addServiceFailure" 
                        v-if="shipment.documentationStatus !== 'CLOSED' && (task.inspectionTaskId && task.inspectionTask && (task.inspectionTask.status === 'Cancelled' || task.inspectionTask.status === 'New' || task.inspectionTask.status === 'Resubmitted' || task.inspectionTask.status === 'Rejected' || task.inspectionTask.status === 'Approved' || task.inspectionTask.status === 'Revoked'))">
                        <v-list-item-action>
                          <v-icon color="blue">
                            refresh
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Resubmit for Inspection
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn v-if="submittingInspection" :loading="submittingInspection"
                            text><v-icon>launch</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item @click="cancelInspection()" 
                        v-if="shipment.documentationStatus == 'OPEN' && task.inspectionTaskId && task.inspectionTask && (task.inspectionTask.status == 'New' || task.inspectionTask.status == 'Resubmitted')">
                        <v-list-item-action>
                          <v-icon color="red">
                            cancel
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Cancel Inspection
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn v-if="cancellingInspection" :loading="cancellingInspection"
                            text><v-icon>launch</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>

                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-subheader style="font-size: 16px">
                      Inspection History
                    </v-subheader>
                    <v-divider v-if="inspectionHistory && inspectionHistory.length > 0"></v-divider>
                    <v-progress-linear indeterminate color="primary" v-if="loadingInspectionHistory"></v-progress-linear>
                    <v-list-item v-if="inspectionHistory.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No history</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="history in inspectionHistory" :key="history.id">
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mr-2" v-on="on" :color="getTaskStatus(history).color">{{
                              getTaskStatus(history).icon
                            }}</v-icon>
                          </template>
                          <span>{{ getTaskStatus(history).name }}</span> </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-content>

                        <v-list-item-title class="text-wrap" v-if="history.start">
                          {{ formatDate(history.start) }} - {{ formatDate(history.end) }}
                        </v-list-item-title>
                        <v-list-item-title class="text-wrap" v-else>
                          Not yet started
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="history.inspectedBy">
                          {{ history.inspectedBy.firstName }} {{ history.inspectedBy.surname }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="history.comments" style="font-size: 12px" class="text-wrap">
                          <v-icon small class="mr-1">description</v-icon> {{ history.comments }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-subheader style="font-size: 16px">
                      Attachments <v-btn icon color="primary" v-if="shipment.documentationStatus == 'OPEN'"
                        @click="inspectionAttachmentModal = true"><v-icon>add_circle_outline</v-icon></v-btn>
                    </v-subheader>
                    <v-divider v-if="inspectionAttachments && inspectionAttachments.length > 0"></v-divider>
                    <v-progress-linear indeterminate color="primary"
                      v-if="loadingInspectionAttachments"></v-progress-linear>
                    <v-list dense>
                      <v-list-item v-if="inspectionAttachments.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No attachments</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="attachment in inspectionAttachments" :key="attachment.id">
                        <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                          <v-img v-if="getDocumentIcon(attachment.shipmentDocument.name)" class="mr-2"
                            style="height: 30px; width: 30px" :src="getDocumentIcon(attachment.shipmentDocument.name)">
                          </v-img>
                          <v-icon v-else-if="doc.name" color="green">check</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ attachment.shipmentDocument.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ formatDate(attachment.shipmentDocument.createdAt) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon color="red" v-if="shipment.documentationStatus == 'OPEN'"
                            @click="deleteInspectionAttachment(attachment)"><v-icon>delete</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>

                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="log" style="background: var(--v-modal-base) !important">
            <v-card flat>
              <v-card-text :key="actionKey">
                <v-timeline align-top dense v-loading="loadingTaskLogs"
                  style="max-height: 45vh; min-height: 10vh; overflow-y: auto">
                  <v-timeline-item color="primary" small icon="add" fill-dot v-if="!loadingTaskLogs">
                    <strong>Task Created</strong>
                    <div class="text-caption">
                      {{ formatDate(task.createdAt) }}
                    </div>
                  </v-timeline-item>
                  <v-timeline-item color="primary" small v-for="log in task.taskLogs" :key="log.id" fill-dot>
                    <strong>{{ log.event }}</strong>
                    <div class="text-caption">
                      {{ formatDate(log.createdAt) }}
                    </div>
                    <div class="text-caption" v-if="log.user">
                      <v-icon small class="mr-1">person</v-icon> {{ log.user.firstname }} {{ log.user.surname }}
                    </div>
                    <div class="text-caption" v-if="log.actionCode">
                      <v-icon small class="mr-1">code</v-icon> Action executed: "{{ log.actionName }}" ({{ log.actionCode
                      }})
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="payload" style="background: var(--v-modal-base) !important">
            <v-card flat>
              <v-card-text class="text-wrap" v-loading="loadingTaskJSON">
                <vue-json-pretty :data="task.requestData" :showLine="false" :virtual="true" :showIcon="true" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="response" style="background: var(--v-modal-base) !important">
            <v-card flat>
              <v-card-text class="text-wrap" v-loading="loadingTaskJSON">
                <vue-json-pretty :data="task.responseData" :showLine="false" :virtual="true" :showIcon="true" />
                <!-- <pre>{{ task.responseData | pretty(4) }}</pre> -->
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <v-dialog v-model="documentSelectModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>
          Attach Document
          <v-spacer></v-spacer>
          <v-btn text @click="(documentSelectModal = false), (documentItem = {})">X</v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ documentItem.name }} ({{
            documentItem.required ? "Required" : "Not Required"
          }})
        </v-card-subtitle>
        <v-card-text>
          <v-autocomplete :items="documents" item-text="name" item-value="key" v-model="documentItem.file" outlined dense
            clearable label="Search documents"></v-autocomplete>
          <v-row justify="center" class="mt-2">
            <v-btn text color="primary" :loading="savingDocumentAttachment" @click="saveDocumentAttachment">Save</v-btn>
          </v-row>
        </v-card-text>
      </v-card></v-dialog>

    <v-dialog v-model="formPreview" width="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <FormModal v-if="form.form" :form="form.form" :key="formKey" @close="formPreview = false" @submit="submitForm" />
    </v-dialog>

    <v-dialog v-model="addAttachmentModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>
          Add Attachment
          <v-spacer></v-spacer>
          <v-btn text @click="(addAttachmentModal = false), (attachmentItem = {})">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-autocomplete label="Select Document Type" item-text="name" item-value="id"
            :items="availableAttachmentDocuments" v-model="attachmentItem.taskAttachment" outlined dense
            return-object></v-autocomplete>
          <v-autocomplete label="Select Uploaded Document" item-text="name" item-value="key" :items="documents"
            v-model="attachmentItem.file" outlined dense></v-autocomplete>
          <v-checkbox v-if="attachmentItem.taskAttachment && attachmentItem.taskAttachment.attestOption"
            label="Attest Document" v-model="attachmentItem.taskAttachment.attest"></v-checkbox>
          <v-row class="mt-2" justify="center">
            <v-btn text color="primary" @click="saveAttachment" :loading="savingDocumentAttachment">Save</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewModal" :fullscreen="$vuetify.breakpoint.mobile || fullscreen" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon @click="(previewModal = false), (previewItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{
          'max-height': fullscreen ? '88vh' : '75vh',
          'overflow-y': 'auto',
          'background-color': 'var(--v-modal-base) !important',
        }">
          <v-row justify="center">
            <v-col cols="12" md="9" lg="8" xl="6" class="text-center">
              <!-- <v-btn @click="annotate">Annotate</v-btn> -->
              <div ref="pdfBox">
                <pdf style="display: none;" :src="previewItem.fullFile" @num-pages="pageCount = new Array($event)" />
                <pdf v-for="(page, index) in pageCount" :key="index" :src="previewItem.fullFile" :page="index + 1"
                  class="contract-container" />
                <!-- <pdf ref="myPdfComponent" :src="previewItem.file" v-for="page in previewItem.pages" :key="page"
                  :page="page"></pdf> -->
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="validationModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Validation Results
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="validationModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 65vh; overflow-y: auto">
          <v-row>
            <v-col cols="12" sm="6">
              <v-subheader><v-icon color="red">error</v-icon> Errors</v-subheader>
              <v-list dense v-for="(container, index) in validationContainer" :key="index" subheader>
                <v-subheader style="color: var(--v-primaryText-base); font-weight: bold">{{ container }}</v-subheader>
                <v-list-item v-for="(pallet) in validationPallets(container)" :key="pallet">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ pallet }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-for="(error, errorIndex) in palletErrors(pallet)" :key="errorIndex"
                      class="text-wrap">
                      {{ error }}
                    </v-list-item-subtitle>
                  </v-list-item-content></v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-subheader><v-icon color="blue">description</v-icon> Attachments</v-subheader>
              <v-list dense v-for="(container, index) in attachmentContainer" :key="index" subheader>
                <v-subheader style="color: var(--v-primaryText-base); font-weight: bold">{{ container }}</v-subheader>
                <div v-for="(pallet) in attachmentPallets(container)" :key="pallet">
                  <v-subheader>Pallet: {{ pallet }}</v-subheader>
                  <v-list-item v-for="(doc, docIndex) in palletDocuments(pallet)" :key="docIndex">
                    <v-list-item-action>
                      <v-avatar tile size="20"><v-img contain :src="getIcon(doc.file)"></v-img> </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ doc.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="previewDocument(doc)"><v-icon>visibility</v-icon></v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-list>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="inspectionModal" fullscreen persistent>
      <v-card>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn text @click="inspectionModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <Inspection :task="inspectionTask" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="submitTaskForInspectionModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Submit Task for Inspection
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="submitTaskForInspectionModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea v-model="task.notes" outlined rows="6"
            placeholder="Add additional notes eg sscc 123456789 found on 2 seperate pages" counter="1000"></v-textarea>
          <v-row justify="center" class="mt-2">
            <v-btn text @click="submitTaskForInspection()" :loading="submittingInspection" color="primary">Submit</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resubmitTaskForInspectionModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Resubmit Task for Inspection
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="resubmitTaskForInspectionModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea v-model="task.notes" outlined rows="6"
            placeholder="Add additional notes eg sscc 123456789 found on 2 seperate pages" counter="1000"></v-textarea>
          <v-row justify="center" class="mt-2">
            <v-btn text @click="resubmitTaskForInspection()" :loading="submittingInspection"
              color="primary">Submit</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="serviceFailureModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent"><v-toolbar-title>{{ this.serviceFailureTitle }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn color="primary" icon :loading="savingServiceFailure"
          :disabled="!serviceFailureItem.serviceFailureCategoryId || (!serviceFailureItem.responsibleOrganisationId && !serviceFailureItem.responsibleParty) || !serviceFailureItem.comment"
            @click="saveServiceFailure"><v-icon>save</v-icon></v-btn>
          <!-- <v-btn text @click="serviceFailureModal = false, serviceFailureItem = {}">X</v-btn> --> </v-toolbar> 
        <v-card-text>
          <v-autocomplete label="Category*" :items="serviceFailureCategories"
            v-model="serviceFailureItem.serviceFailureCategoryId"
            @change="subcategoryKey++, serviceFailureItem.serviceFailureSubcategoryId = null" outlined dense clearable
            item-text="name" item-value="id"></v-autocomplete>

          <v-autocomplete :items="availableParties" v-model="serviceFailureItem.responsibleOrganisationId"
            label="Responsible Party" outlined dense clearable item-text="name" item-value="value"></v-autocomplete>
          <v-textarea outlined rows="8" v-model="serviceFailureItem.comment" placeholder="Description"></v-textarea>
          <!-- <v-row class="mt-2" justify="center">
            <v-btn @click="deleteServiceFailure()" :loading="deletingServiceFailure"
              v-if="serviceFailureItem.id && serviceFailureItem.organisationId == $store.state.currentOrg.id" color="red"
              style="text-transform: none"><v-icon>delete</v-icon> Delete</v-btn>

          </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="inspectionAttachmentModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Add Inspection Attachment
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="inspectionAttachmentModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-subheader>Select existing document:</v-subheader>
              <v-list style="max-height: 60vh; overflow-y: auto" dense>
                <v-progress-linear v-if="addingInspection" color="primary" indeterminate></v-progress-linear>
                <v-list-item v-for="(doc, index) in documents" :key="index" @click="addInspectionAttachment(doc)">
                  <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                    <v-img v-if="getDocumentIcon(doc.name)" class="mr-2" style="height: 30px; width: 30px"
                      :src="getDocumentIcon(doc.name)">
                    </v-img>
                    <v-icon v-else-if="doc.name" color="green">check</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ doc.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatDate(doc.createdAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-subheader>Upload new document:</v-subheader>
              <v-file-input v-if="!uploadingInspectionAttachment" v-model="inspectionAttachment" label="Upload document"
                outlined></v-file-input>
              <div v-else>
                <span>Uploading Document {{ uploadProgress }}%</span>
                <v-progress-linear class="mt-1" :value="uploadProgress"></v-progress-linear>

              </div>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Task & Actions - Tasks!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import dateFormat from "dateformat";
import FormModal from "@/components/SystemComponents/FormModal.vue";
import Inspection from '@/components/SystemComponents/InspectionModal.vue';
import pdf from "vue-pdf";

import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import tsSteps from './Steps/taskModalSteps'

export default {
  props: ["task", "actions", "bookings", "documents", "documentTypes", "documentUpdateKey", "shipment", "statusKey", "fileNumber", "taskKey","parentDoc"],
  components: {
    FormModal,
    Inspection,
    pdf
  },
  data: () => ({
    serviceFailureCategories: [],
    subcategoryKey: 0,
    serviceFailureTitle: '',
    savingServiceFailure: false,
    // serviceFailures:[],
    serviceFailureModal: false,
    serviceFailureItem: {},
    titanStatus: false,
    actionKey: 1000,
    addingInspection: false,
    availableForms: [],
    addAttachmentModal: false,
    attachmentItem: {},
    attachmentKey: 2000,
    cancellingInspection: false,
    documentKey: 3000,
    documentItem: {},
    documentSelectModal: false,
    docLoading: null,
    form: {},
    formKey: 4000,
    formPreview: false,
    fullscreen: false,
    loadingForm: false,
    inspectionAttachment: null,
    inspectionAttachments: [],
    inspectionAttachmentKey: 5000,
    inspectionAttachmentModal: false,
    inspectionHistory: [],
    inspectionTask: {},
    inspectionModal: false,
    inspectionTaskKey: 7000,
    loadingInspectionAttachments: false,
    loadingInspectionHistory: false,
    loadingTaskActions: false,
    loadingTaskJSON: false,
    loadingTaskLogs: false,
    loadingAction: null,
    loadingKey: 8000,
    pageCount: [],
    previewItem: {},
    previewModal: false,
    loadingAttachments: false,
    resubmitTaskForInspectionModal: false,
    submitTaskForInspectionModal: false,
    savingDocumentAttachment: false,
    shipmentDocumentItem: null,
    submittingInspection: false,
    selectedTab: "info",
    startingTask: false,
    taskActionKey: 9000,
    taskActions: [],
    taskAttachments: [],
    taskInspectionComment: null,
    uploadingInspectionAttachment: false,
    uploadProgress: 0,
    validating: false,
    validationModal: false,
    infoStart: false
  }),
  watch: {
    taskKey(val){
      if(val){
        console.log(this.task)
        this.taskActionKey ++
      }
    },
    "task.id": {
      immediate: true,
      handler(val) {
        if (val) {
          console.log("Task ID change", val)
          this.taskAttachments = []
          this.inspectionHistory = []

          this.loadTaskLogs();
          this.getTaskActions()
          this.getTaskAttachments();
          this.getTaskJSONData();
          this.getInspectionAttachments()
          if (this.task.inspectionTaskId) {
            this.getInspectionTask();
          }
          this.checkTitanStatus();
          // console.log(this.task)
          // this.actions = [];
          if (this.task.parentShipmentDocumentId) {
            this.shipmentDocumentItem = this.documentTypes.find(x => x.id == this.task.parentShipmentDocumentId)
          } else if (this.task.shipmentDocumentTypeId) {
            this.shipmentDocumentItem = this.documentTypes.find(x => x.id == this.task.shipmentDocumentTypeId)
          } else {
            this.shipmentDocumentItem = null
          }
          this.loadingKey++
        }
      },
    },
    "task.status": {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadTaskLogs();
          this.actionKey++
        }
      },
    },
    "task.taskAttachments": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTaskAttachments();
        }
      },
    },
    async inspectionAttachment(val) {
      console.log(val)
      if (val) {
        this.uploadProgress = 0
        this.uploadingInspectionAttachment = true
        let url = await URL.createObjectURL(val);
        this.toDataUrl(url, (data) => {
          let obj = {
            name: val.name,
            data: data,
            type: val.type
          };
          this.submitUpload(obj);
        });
      } else {
        this.uploadingInspectionAttachment = false
      }

    }
  },
  computed: {
    availableParties() {
      let result = []
      let parties = ['shipper', 'onBehalfShipper', 'forwarder', 'onBehalfForwarder', 'consignee', 'onBehalfConsignee', 'buyer', 'onBehalfBuyer', 'firstNotify', 'secondNotify', 'courierParty']
      for (let i = 0; i < parties.length; i++) {
        if (this.shipment[parties[i]]) {
          let findExisting = result.find(x => x.value == this.shipment[parties[i] + 'Id'])
          if (!findExisting) {
            result.push({
              name: this.shipment[parties[i]].name,
              value: this.shipment[parties[i] + 'Id']
            })
          }
        }
      }
      if (this.relationships && this.relationships.length > 0) {
        for (let i = 0; i < this.relationships.length; i++) {
          let findExisting = result.find(x => x.value == this.relationships[i].customerId)
          if (!findExisting) {
            result.push({
              name: this.relationships[i].customer.name,
              value: this.relationships[i].customerId
            })
          }
        }
      }
      result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      result.push({ name: 'Other', value: null })
      return result

    },
    actionAvailable() {
      let result = null;
      if (this.documentTypes && this.documentTypes.length > 0) {
        let flattened = [...this.documentTypes, ...[].concat.apply([], this.documentTypes.map(x => x.variations))]
        let findDoc = flattened.find(
          (x) =>
            (x.id == this.task.shipmentDocumentTypeId ||
              x.id == this.task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            result = findAction;
          }
        }
      }
      return result;
    },
    availableConsigneeAddresses() {
      let result = [];
      if (this.shipmentDocumentItem && this.shipment.consignee) {
        result.push({
          name: this.shipment.consignee.name,
          value: "consignee",
        });
      }
      if (this.shipmentDocumentItem && this.shipment.onBehalfConsignee) {
        result.push({
          name: this.shipment.onBehalfConsignee.name,
          value: "onBehalfConsignee",
        });
      }
      return result;
    },
    availableShipperAddresses() {
      let result = [];
      if (this.shipmentDocumentItem && this.shipment.shipper) {
        result.push({
          name: this.shipment.shipper.name,
          value: "shipper",
        });
      }
      if (this.shipmentDocumentItem && this.shipment.onBehalfShipper) {
        result.push({
          name: this.shipment.onBehalfShipper.name,
          value: "onBehalfShipper",
        });
      }
      return result;
    },
    availableAttachmentDocuments() {
      let result = this.taskAttachments
        ? this.taskAttachments.filter((x) => !x.file)
        : [];
      return result
    },
    documentContainer() {
      let result = [];
      if (this.task && this.task.shipmentDocumentTypeId) {
        let documentId = this.task.parentShipmentDocumentId ?? this.task.shipmentDocumentTypeId
        let containers = this.documentTypes.find(x => x.id == documentId)
        if (containers) {
          containers = containers.shipmentDocumentTypeContainers.map(
            (x) => x.bookingContainerId
          );
          result = this.bookings.filter((x) => containers.includes(x.id));

        }

      }
      return result;
    },
    filterTaskActions() {
      let result = [];
      result = this.taskActions.filter((x) =>
        x.statuses.includes(this.task.status)
      );
      return result;
    },
    // filterTaskAttachments(){
    //   // console.log(this.task.taskAttachments.sort((a, b) => (a.file > b.file ? 1 : b.file > a.file ? -1 : 0)))
    //   let result = this.task.taskAttachments.sort((a,b) => (a.file > b.file) ? 1 : ((b.file > a.file) ? -1 : 0))
    //   return result
    // },
    validationContainer() {
      let result = []
      if (this.task && this.task.validationResult) {
        result = [...new Set(this.task.validationResult.map(x => x.containerNo))]
      }
      return result
    },
    attachmentContainer() {
      let result = []
      if (this.task && this.task.validationAttachment) {
        result = [...new Set(this.task.validationAttachment.map(x => x.containerNo))]
      }
      return result
    }
  },
  created() {
    this.getServiceFailureCategories();
  },
  mounted() {
    this.selectedTab = "info";
    this.driver = new Driver({
      animate: false
    })
  },
  methods: {
    async updateTaskPoll(){
      await this.$API.updateTaskPoll(
        {
          taskId: this.task.id,
          poll:this.task.poll
        }
      )
    },
    back(){
      if(this.task.isChild){
        this.$emit('viewSubtask', this.task.parent)
      }
    },
    async getServiceFailureCategories() {
      this.serviceFailureCategories = await this.$API.getServiceFailureCategories();
    },
   async addServiceFailure() {
      this.serviceFailureItem = {
        responsibleOrganisationId: this.$store.state.currentOrg.id
      }
      if (this.task.title == 'PPECB Addendum : PPECB Manual Addendum - Fresh Fruit & Veg') {
        // this.serviceFailures=[
        //   'Integration API issue',
        //   'Outdated data'
        // ]
        this.serviceFailureTitle = this.titanStatus ? 'Reason for PPECB fail' : 'Reason why not using PPECB'
      }
      else {
        // this.serviceFailures=[
        //   'Integration API issue',
        //   'Outdated data',
        //   'PPECB fail'
        // ]
        this.serviceFailureTitle = 'Other resubmit reason'
      }
      this.serviceFailureModal = true
    },
    actionRequired() {
      let result = false;
      if (this.documentTypes && this.documentTypes.length > 0) {
        let findDoc = this.documentTypes.find(
          (x) =>
            (x.id === this.task.shipmentDocumentTypeId ||
              x.id === this.task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            result = findAction ? findAction.status != "Complete" : true;
          }
        }
      }
      return result;
    },
    async addInspectionAttachment(item) {
      this.addingInspection = true
      await this.$API.createInspectionAttachment({
        taskId: this.task.id,
        shipmentDocumentId: item.id
      });
      this.getInspectionAttachments()
      this.inspectionAttachmentModal = false
      this.addingInspection = false
    },
    attachDocument(doc) {
      this.documentItem = doc;
      this.documentSelectModal = true;
    },
    availableActions(task) {
      return task ? this.taskActions.filter((x) => x.statuses.includes(task.status) && (!x.submissionType || x.submissionType.includes(task.submissionType))) : [];
    },
    async completeForm() {
      if (this.documentTypes && this.documentTypes.length > 0) {
        let flattened = [...this.documentTypes, ...[].concat.apply([], this.documentTypes.map(x => x.variations))]
        let findDoc = flattened.find(
          (x) =>
            (x.id == this.task.shipmentDocumentTypeId ||
              x.id == this.task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            this.loadingForm = true
            this.form = await this.$API.getShipmentActionDetail(findAction.id);
            this.loadingForm = false
            // this.form = findAction;
            this.form.form.formResponse = this.form.response;
            this.formPreview = true;
            this.formKey++
          }
        }
      }
    },
    documentsRequired() {
      let requiredDocs = this.taskAttachments.filter(
        (x) => x.required && !x.file
      );
      let existingDocs = this.taskAttachments.filter((x) => x.file);
      let findMatches = requiredDocs.filter((x) =>
        existingDocs.map((y) => y.name).includes(x.name)
      );
      return (this.task.attachmentRequired && this.taskAttachments.filter((x) => x.file).length == 0) || (requiredDocs.length > 0 && findMatches.length == 0);
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "dd/mm/yyyy HH:MM");
      }
      return result;
    },
    async deleteInspectionAttachment(item) {
      this.$confirm(
        "Are you sure you want to delete this inspection attachment?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          item.isDeleted = true;
          item.isActive = false;
          await this.$API.updateInspectionTaskAttachment(item);
          let index = this.inspectionAttachments.findIndex(
            (x) => x.id == item.id
          );
          this.inspectionAttachments.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async checkTitanStatus() {
      // this.checkingTitan = true
      if (this.task.title == 'PPECB Addendum : PPECB Manual Addendum - Fresh Fruit & Veg') {
        console.log('parentshipmentdocumet', this.task.parentShipmentDocumentId)
        console.log('taskid', this.task.id)
        let titanStatus = await this.$API.getTitanStatus({
          parentShipmentDocumentId: this.task.parentShipmentDocumentId,
          id: this.task.shipmentDocumentTypeId
        })
        // titanStatus = titanStatus.status == 'Not Started' || titanStatus.status == 'Not Created' ? false : true
        titanStatus = titanStatus.submittedDate ? true : false
        this.titanStatus = titanStatus
      }
    },

    async deleteAttachment(item) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          item.isDeleted = true;
          item.isActive = false;
          await this.$API.deleteTaskAttachment(item);
          let index = this.taskAttachments.findIndex(
            (x) => x.id == item.id
          );
          this.taskAttachments.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async getInspectionHistory(id) {
      this.loadingInspectionHistory = true;
      this.inspectionHistory = await this.$API.getAllInspectionResults(id)
      this.loadingInspectionHistory = false
    },
    async getInspectionTask() {
      this.loadingInspectionTask = true;
      let taskResult = await this.$API.getInspectionTask(this.task.id);
      this.task.inspectionTask = taskResult
      this.getInspectionHistory(taskResult.id)
      this.$emit('update', this.task)
      this.loadingInspectionTask = false
      this.inspectionTaskKey++
    },
    async previewDocument(doc) {
      this.docLoading = doc.id;
      this.documentKey++
      this.previewItem = this.documents.find(x => x.key == doc.file)
      if (!this.previewItem) {
        this.previewItem = {}
      }
      let file = await this.$API.downloadFile({
        key: doc.file,
      });
      this.previewItem.fullFile = file.file;
      this.previewItem.fileType = file.fileType;
      this.previewModal = true
      this.docLoading = null
      this.documentKey++
    },
    async downloadDocument(doc) {

      this.documentKey++;
      let file = await this.$API.downloadFile({
        key: doc.file,
      });
      doc.fullFile = file.file;
      doc.fileType = file.fileType;
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.fullFile;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      this.docLoading = null;
      this.documentKey++;
    },
    downloadRequest() {
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.task.requestData));
      var dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "request_" + this.task.id + ".json");
      dlAnchorElem.click();
    },
    downloadResponse() {
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.task.responseData));
      var dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "response_" + this.task.id + ".json");
      dlAnchorElem.click();
    },
    async downloadJSON(item) {
      console.log('item', item)
      let obj = {
        shipmentId: item.shipmentId,
        containerId: item && item.bookingContainerId ? item.bookingContainer : this.documentContainer,
        shipmentDocumentTypeId: item.shipmentDocumentTypeId,
        taskId: item.id
      }
      try {
        this.loadingContainerPalletData = true
        // console.log(obj)
        let res = await this.$API.getShipmentContainerPalletData(obj)

        let jsonData = JSON.stringify(res)
        let blob = new Blob([jsonData], { type: "application/json" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${item.bookingContainer.containerNo}.json`;
        a.click();
        URL.revokeObjectURL(url);

        this.loadingContainerPalletData = false
      }
      catch (err) {
        console.log('err', err)
      }
    },
    confirmAction(action) {
      this.$confirm(action.confirmationText, "Confirm Action", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    
                  this.executeAction(action)
                })
    },
    async executeAction(action) {
      this.loadingAction = action.code;
      // Id for modal
      let id = this.task.parentShipmentDocumentId ? this.task.parentShipmentDocumentId : this.task.shipmentDocumentItemId ? this.task.shipmentDocumentItemId : this.task.shipmentDocumentTypeId ? this.task.shipmentDocumentTypeId : this.task.id
      let submittedDate =false
      if (this.task.parentShipmentDocumentId){
        let res= await this.$API.getShipmentDocumentTypes(this.task.parentShipmentDocumentId)
        console.log('res van getShipmentDocumentTypes' , res)
        submittedDate = res.submittedDate ? true : false
      }
      console.log('action', action) // kla in code
      console.log('my id', id)
      if (action.name === "Resubmit" || action.name === 'Retry Submission' || action.name === 'Submit Replacement Application') {
        if (action.name == "Retry Submission" && !submittedDate) {
          // await this.$API.updateShipmentDocumentType({
          //   id: id,
          //   status: 'Submitted',
          //   submittedDate: new Date(),
          //   updatedById: this.$store.state.user.id

          // });
        }
        else {
          await this.addServiceFailure()
          // await this.$API.updateShipmentDocumentType({
          //   id: id,
          //   status: 'Resubmitted',
          //   resubmitDate: new Date(),
          //   updatedById: this.$store.state.user.id

          // });

        }

      }
      if (this.task.documentationCode) {
        if (this.task.responseData) {
          if (typeof this.task.responseData == 'string') {
            this.task.responseData = this.task.payload
          }
          this.task.responseData.taskId = this.task.id;
        } else {
          this.task.payload.taskId = this.task.id;
        }
        let result = await this.$API.executeDocument(
          action.code,
          this.task.id,
          { ...this.task.payload, ...this.task.responseData }
        );
        this.task = { ...this.task, ...result };
      } else if (this.task.integrationCode) {
        if (this.task.responseData) {
          if (typeof this.task.responseData == 'string') {
            this.task.responseData = this.task.payload
          }
          this.task.responseData.taskId = this.task.id;
        } else {
          this.task.payload.taskId = this.task.id;
        }
        let result = await this.$API.executeIntegration(
          action.code,
          this.task.id,
          { ...this.task.payload, ...this.task.responseData },
          {
            type: 'action'
          }
        );
        this.task = { ...this.task, ...result };
        this.$message.success("Action executed successfully!");
      }
      this.loadingAction = null;
      setTimeout(() => { this.getTaskAttachments(); }, 2000)
      // this.task.taskLogs = await this.$API.getTaskLogs(this.task.id);
      this.$emit('refreshAttachments', this.task)
      this.loadTaskLogs();
      this.getTaskActions()
    },
    getIcon(name) {
      if (name) {
        let type = name.split(".");
        let result = null;
        if (type.length > 0) {
          result = type.at(-1).toLowerCase();
        }
        if (result && result.toLowerCase() == "xlsx") {
          result = "xls";
        }
        return `/icons/${result}.svg`;
      }
      return null

    },
    async getTaskAttachments() {
      this.loadingAttachments = true;
      this.taskAttachments = await this.$API.getTaskAttachments(
        this.task.id
      );
      this.attachmentKey++;
      this.loadingAttachments = false;
    },
    async getInspectionAttachments() {
      this.inspectionAttachments = []
      this.loadingInspectionAttachments = true
      this.inspectionAttachments = await this.$API.getInspectionAttachments(this.task.id)
      // console.log(this.inspectionAttachments)
      this.inspectionAttachmentKey++
      this.loadingInspectionAttachments = false;
    },
    getDocumentIcon(name) {
      let type = name.split(".");
      let result = null;
      if (name.includes(".") && type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return result ? `/icons/${result}.svg` : null;
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "in progress":
          return "indigo";
        case "submitted":
          return "blue";
        case "draft":
          return "blue";
        case "file generated":
          return "orange";
        case "pending":
          return "orange";
        case status.includes("pending"):
          return "orange";
        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "error":
          return "red";
        case "not created":
          return "red";
        case "validation failed":
          return "red";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "not required":
          return "close";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "draft":
          return "edit_note";
        case "submitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "file generated":
          return "task";
        case "error":
          return "cancel";
        case "not created":
          return 'error';
        case "validation failed":
          return 'error';
        default:
          return "info";
      }
    },

    getTaskStatus(task) {
      switch (task.status) {
        case "New":
          return { name: task.status, icon: "grade", color: "orange" };
        case "Not started":
          return { name: task.status, icon: "schedule", color: "grey" };
        case "Pending":
          return { name: task.status, icon: "pending", color: "orange" };
        case "Resubmitted":
          return { name: task.status, icon: "sync", color: "orange" };
        case "In Progress":
          return { name: task.status, icon: "sync", color: "blue" };
        case "Complete":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "File Generated":
          return { name: task.status, icon: "task", color: "orange" };
        case "Success":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: task.status, icon: "cancel", color: "grey" };
        case "Not Created":
          return { name: task.status, icon: "error", color: "red" };
        case "Rejected":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Draft":
          return { name: task.status, icon: "edit_note", color: "orange" };
        case "Error":
          return { name: task.status, icon: "error", color: "red" };
        case "Failed":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Submitted":
          return { name: task.status, icon: "mark_email_read", color: "blue" };
        case "Not Required":
          return { name: task.status, icon: "close", color: "grey" };
        case "Approved":
          return { name: task.status, icon: "fact_check", color: "green" };
        case "Validation Failed":
        return { name: task.status, icon: "error", color: "red" };

        case "Revoked":
          return { name: task.status, icon: "error", color: "red" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: task.status,
          };
      }
    },
    palletErrors(pallet) {
      let errors = this.task.validationResult.filter(x => x.sscc == pallet).map(x => x.message)
      return errors
    },
    palletDocuments(pallet) {
      let documents = this.task.validationAttachment.filter(x => x.sscc == pallet)
      return documents
    },
    async checkParentDoc(task) {
        if(this.parentDoc.status == "Complete") {
        // console.log(this.parentDoc)
        this.$confirm("Are you sure you want to Start this task, the system indicates that this document has already been completed?", "Confirm Start Task", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    
                  this.startTask(task)
                })
      } else {
        this.startTask(task)
      }
      // console.log()
    },
    async startTask() {
      this.startingTask = true
      this.task.status = "In Progress";
      await this.$API.startTask(this.task);
      this.startingTask = false
      // await this.$API.updateTask({
      //   id: this.task.id,
      //   status: "Pending",
      // });
      this.$emit('update', this.task)

    },
    async getTaskActions() {
      if(this.task && this.task.id){
      this.loadingTaskActions = true;
      this.taskActions = []
      this.taskActions = await this.$API.getTaskActions(this.task.id);
      this.actionKey++;
      this.taskActionKey++
      this.loadingTaskActions = false;
      }
    },
    async loadTaskLogs() {
      this.task.taskLogs = [];
      this.loadingTaskLogs = true;
      this.task.taskLogs = await this.$API.getTaskLogs(this.task.id);
      this.actionKey++;
      this.loadingTaskLogs = false;
    },
    async getTaskJSONData() {
      this.loadingTaskJSON = true;
      let taskData = await this.$API.getTaskJSONData(this.task.id);
      this.task = { ...this.task, ...taskData }
      this.loadingTaskJSON = false;
    },
    async saveDocumentAttachment() {
      this.savingDocumentAttachment = true;
      this.documentSelectModal = false;
      await this.$API.updateTaskAttachment(this.documentItem);
      this.documentSelectModal = false;
      this.documentItem = {};
      this.savingDocumentAttachment = false;
    },
    async saveAttachment() {
      this.savingDocumentAttachment = true;
      let obj = this.attachmentItem.taskAttachment;
      delete obj.id;
      delete obj.createdAt;
      delete obj.updatedAt;
      obj.file = this.attachmentItem.file;
      await this.$API.createTaskAttachment(obj);
      this.addAttachmentModal = false;
      this.attachmentItem = {};
      this.savingDocumentAttachment = false;
      this.getTaskAttachments();
      this.$emit('refreshAttachments', this.task)
    },
    async submitForm(response) {
      this.form.response = response;
      this.form.userId = this.$store.state.user.id;
      this.form.user = this.$store.state.user;
      this.form.status = "Complete";
      let action = this.actions.find(
              (x) => x.id === this.form.id
            );
            action.status = "Complete";
      await this.$API.submitShipmentActions(this.form);
      this.formPreview = false;
      this.form = {};
    },
    async submitTaskForInspection() {
      this.submittingInspection = true;
      let result = await this.$API.submitTaskForInspection(this.task.id, this.task)
      let status = this.titanStatus == true ? 'Resubmitted' : 'Submitted'
      if(status == 'Submitted'){
        await this.$API.updateShipmentDocumentType({
        id: this.task.parentShipmentDocumentId ? this.task.parentShipmentDocumentId : this.task.shipmentDocumentId ? this.task.shipmentDocumentId : this.task.shipmentDocumentTypeId,
        submittedDate: new Date(),
        status:'Submitted'
      });
      }
      else if(status == 'Resubmitted'){
        await this.$API.updateShipmentDocumentType({
        id: this.task.parentShipmentDocumentId ? this.task.parentShipmentDocumentId : this.task.shipmentDocumentId ? this.task.shipmentDocumentId : this.task.shipmentDocumentTypeId,
        resubmitDate: new Date(),
        status:'Resubmitted'
      });
      }
     
      this.task.inspectionStatus = result.status
      this.task.inspectionTask = result
      this.task.inspectionTaskId = result.id
      this.submitTaskForInspectionModal = false
      this.taskInspectionComment = null
      this.submittingInspection = false;
    },
    async cancelInspection() {
      this.cancellingInspection = true;
      await this.$API.cancelInspection(this.task)
      this.getInspectionTask()
      this.cancellingInspection = false;
    },
    async resubmitTaskForInspection() {
      this.submittingInspection = true;
      // console.log('task', this.task)
      let response = await this.$API.resubmitTaskForInspection(this.task.id, this.task)
      // console.log('response', response)
      await this.$API.updateShipmentDocumentType({
        id: this.task.parentShipmentDocumentId ? this.task.parentShipmentDocumentId : this.task.shipmentDocumentId ? this.task.shipmentDocumentId : this.task.shipmentDocumentTypeId,
        status: 'Resubmitted',
        integrationStatus: 'Resubmitted',
        resubmitDate: new Date(),
      });
      this.getInspectionTask()
      this.resubmitTaskForInspectionModal = false
      this.submittingInspection = false;
    },
    async saveServiceFailure() {
      this.savingServiceFailure = true;
      console.log('this task', this.task)
      let roboDanieTitle = this.task.title == 'PPECB Export Certificate (PPECB Addendum): PPECB Manual Addendum - Fresh Fruit & Veg' || this.task.title=='PPECB Export Certificate (PPECB Addendum): PPECB Manual Addendum - Stone Fruit'|| this.task.title == ' PPECB Addendum : PPECB Manual Addendum - Fresh Fruit & Veg' ? true : false
      if (this.serviceFailureItem.id) {
        await this.$API.updateServiceFailure(this.serviceFailureItem)
      } else {
        this.serviceFailureItem.type = 'document'
        // this.serviceFailureItem.shipmentDocumentTypeId = this.task.shipmentDocumentTypeId
        this.serviceFailureItem.shipmentDocumentTypeId = this.task.parentShipmentDocumentId ? this.task.parentShipmentDocumentId : this.task.shipmentDocumentTypeId
        this.serviceFailureItem.shipmentId = this.shipment.id
        await this.$API.createServiceFailure(this.serviceFailureItem);
        let roboDanieStatus = this.titanStatus ? 'Resubmitted' : 'Submitted'
        // let res = await this.$API.updateShipmentDocumentType({
        //   id: this.serviceFailureItem.shipmentDocumentTypeId,
        //   status: roboDanieTitle ? roboDanieStatus : 'Resubmitted',
        // });
      }
      if (roboDanieTitle) {
        if (!this.task.inspectionStatus) {
          await this.submitTaskForInspection()
        }
        else {
          await this.resubmitTaskForInspection();
        }
      }
      this.serviceFailureModal = false;
      this.serviceFailureItem = {};
      this.savingServiceFailure = false;
    },
    async updateDocumentType() {
      let obj = {
        id: this.shipmentDocumentItem.id,
        displayOnBehalfShipperName: this.shipmentDocumentItem.displayOnBehalfShipperName,
        shipperAddress: this.shipmentDocumentItem.shipperAddress,
        displayOnBehalfConsigneeName: this.shipmentDocumentItem.displayOnBehalfConsigneeName,
        consigneeAddress: this.shipmentDocumentItem.consigneeAddress
      }
      await this.$API.updateShipmentDocumentType(obj)
      this.$emit('updateDocument', this.shipmentDocumentItem)
    },
    attachmentPallets(containerNo) {
      let result = []
      if (this.task && this.task.validationAttachment) {
        result = [...new Set(this.task.validationAttachment.filter(x => x.containerNo == containerNo).map(x => x.sscc))]
      }
      return result
    },
    async submitUpload(obj) {
      let Api = axios.create({
        // baseURL: "http://localhost:3000",
        baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      let result = await Api.post("/upload/document", obj, {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.ceil(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        }
      });
      result = result.data;
      await this.$API.createInspectionAttachment({
        taskId: this.task.id,
        shipmentDocumentId: result.id
      });
      this.uploadingInspectionAttachment = false
      this.getInspectionAttachments()
      this.inspectionAttachmentModal = false
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },

    validationPallets(containerNo) {
      let result = []
      if (this.task && this.task.validationResult) {
        result = [...new Set(this.task.validationResult.filter(x => x.containerNo == containerNo).map(x => x.sscc))]
      }
      return result
    },
    async validateTask(task) {
      this.validating = true;
      this.loadingKey++
      let result = await this.$API.getTaskValidation(task.id)
      // this.$emit('update', { ...this.task, ...result })
      this.task = { ...this.task, ...result }
      this.validating = false
      this.loadingKey++
      this.inspectionTaskKey++
    },
    viewInspection(task) {
      this.inspectionTask = task
      this.inspectionModal = true
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(tsSteps)
      this.driver.start()
    },
    viewSubtask(subtask){
      subtask.isChild = true
      subtask.parent = this.task
      this.$emit('viewSubtask', subtask)
    }

  },
};
</script>
