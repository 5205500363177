const steps = [
  {
    element: "#document-ref",
    popover: {
      title: "Document - Reference",
      description:
        "This is where you input information regarding the document reference",
      position: "top",
    },
  },
  {
    element: "#document-sub-date",
    popover: {
      title: "Document - Submission Date",
      description:
        "This is where you'll see the due date for the document to be submitted or when the document was submitted",
      position: "top",
    },
  },
  {
    element: "#document-comp-date",
    popover: {
      title: "Document - Completed Date",
      description:
        "This is where you'll see the due date for the document to be completed or when the document was completed",
      position: "top",
    },
  },
  {
    element: "#document-notes",
    popover: {
      title: "Document - Notes",
      description: "This is where you'll see all notes regarding this document",
      position: "top",
    },
  },
  {
    element: "#document-special-instruc",
    popover: {
      title: "Document - Special Instructions",
      description:
        "This is where you'll see all special instructions regarding this document",
      position: "top",
    },
  },
  {
    element: "#shipper-address",
    popover: {
      title: "Shipper Document Address",
      description:
        "This is where you'll see all the available shipper addresses",
      position: "top",
    },
  },
  {
    element: "#shipper-display",
    popover: {
      title: "Display on behalf shipper name",
      description:
        "This is where you'll see the shipper's name who this is behalf of",
      position: "top",
    },
  },
  {
    element: "#consignee-address",
    popover: {
      title: "Consignee Document Address",
      description:
        "This is where you'll see all the available consignee addresses",
      position: "top",
    },
  },
  {
    element: "#consignee-display",
    popover: {
      title: "Display on behalf consignee name",
      description:
        "This is where you'll see the consignee's name who this is behalf of",
      position: "top",
    },
  },
  {
    element: "#document-containers",
    popover: {
      title: "Document - Linked Container(s)",
      description:
        "This is where you'll see the linked container(s) of this specific document",
      position: "top",
    },
  },
  {
    element: "#document-service-failures",
    popover: {
      title: "Document - Service Failures",
      description:
        "This is where you'll see the service failure(s) of this specific document",
      position: "top",
    },
  },
  {
    element: "#document-status-log",
    popover: {
      title: "Document - Status Log",
      description:
        "This is where you'll see the status log of this specific document",
      position: "top",
    },
  },
  {
    element: "#document-tasks",
    popover: {
      title: "Document - Tasks",
      description:
        "This is where you'll see all the information regarding tasks of this specific document",
      position: "top",
    },
  },
  {
    element: "#document-media",
    popover: {
      title: "Document - Media",
      description:
        "This is where you'll see all the information regarding media of this specific document",
      position: "top",
    },
  },
];

export default steps;
