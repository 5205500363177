<template>
  <div>
    <v-card rounded="0" flat>
      <v-toolbar flat>
        <v-toolbar-title>Shipping Instruction</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="debugViewEnabled"
          text
          @click="submitDraftDialog = true"
          color="primary"
          >Submit Draft</v-btn
        >
        <v-btn icon @click="$emit('close')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container style="max-width: 75vw">
        <template v-if="loadingData">
          <valhalla-loading height="100vh" />
        </template>
        <template v-else-if="validData">
          <!-- SI Info -->
          <v-sheet class="pa-4" rounded>
            <v-row>
              <v-col>
                <h1>Shipping Instruction</h1>
              </v-col>
              <v-col v-if="debugViewEnabled" cols="auto">
                <v-btn icon @click="viewDebugDialog">
                  <v-icon>quiz</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <span class="font-weight-bold">SI Requestor Company:</span>
                  {{ resultData.organisationData.name }}
                </div>
                <div>
                  <span class="font-weight-bold mr-2">Created </span
                  ><span class="font-weight-bold mx-2">On</span
                  >{{ entity.ShipmentInstruction.CreationDate }}
                </div>
              </v-col>
            </v-row>
          </v-sheet>

          <v-form>
            <!-- General Details -->
            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col>
                  <h2>General Details</h2>
                </v-col>
              </v-row>
              <v-row>
                <!-- Parties -->
                <v-col cols="12" md="4">
                  <v-col>
                    <v-toolbar flat dense>
                      <v-toolbar-title style="font-size: 16px"
                        >Shipper</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        style="text-transform: none"
                        small
                        text
                        color="primary"
                        @click="addContactDetail('shipper')"
                      >
                        Edit Party Details
                      </v-btn>
                    </v-toolbar>
                    <v-col>
                      <v-textarea
                        v-model="
                          entity.ShipmentInstruction.SICompanies.shipper
                            .CompanyName
                        "
                        outlined
                        label="Shipper"
                        :height="80"
                        no-resize
                      ></v-textarea>
                      <v-textarea
                        v-model="
                          entity.ShipmentInstruction.SICompanies.shipper
                            .CompanyAddress
                        "
                        dense
                        hide-details
                        outlined
                        label="Address (Printed on B/L)"
                      ></v-textarea>
                    </v-col>
                  </v-col>
                  <v-col>
                    <v-toolbar flat dense>
                      <v-toolbar-title style="font-size: 16px"
                        >Forwarder</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        style="text-transform: none"
                        small
                        text
                        color="primary"
                        @click="addContactDetail('forwarder')"
                      >
                        Edit Party Details
                      </v-btn>
                    </v-toolbar>
                    <v-col>
                      <v-textarea
                        v-model="
                          entity.ShipmentInstruction.SICompanies.forwarder
                            .CompanyName
                        "
                        outlined
                        label="Forwarder"
                        :height="80"
                        no-resize
                      ></v-textarea>
                      <v-textarea
                        v-model="
                          entity.ShipmentInstruction.SICompanies.forwarder
                            .CompanyAddress
                        "
                        dense
                        hide-details
                        outlined
                        label="Address (Printed on B/L)"
                      ></v-textarea>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col cols="12" md="4">
                  <v-col>
                    <!-- <v-col>
                      <label class="mb-6">Consignee</label>
                    </v-col> -->
                    <v-toolbar flat dense>
                      <v-toolbar-title style="font-size: 16px"
                        >Consignee</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        style="text-transform: none"
                        small
                        text
                        color="primary"
                        @click="addContactDetail('consignee')"
                      >
                        Edit Party Details
                      </v-btn>
                    </v-toolbar>
                    <v-col>
                      <v-textarea
                        v-model="
                          entity.ShipmentInstruction.SICompanies.consignee
                            .CompanyName
                        "
                        outlined
                        label="Consignee"
                        :height="80"
                        no-resize
                      ></v-textarea>
                      <v-textarea
                        v-model="
                          entity.ShipmentInstruction.SICompanies.consignee
                            .CompanyAddress
                        "
                        dense
                        hide-details
                        outlined
                        label="Address (Printed on B/L)"
                      ></v-textarea>
                    </v-col>
                  </v-col>
                  <v-col>
                    <!-- <v-col>
                      <label class="mb-6">Notify Party</label>
                    </v-col> -->
                    <v-toolbar flat dense>
                      <v-toolbar-title style="font-size: 16px"
                        >Notify Party</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        style="text-transform: none"
                        small
                        text
                        color="primary"
                        @click="addContactDetail('notifyParty')"
                      >
                        Edit Party Details
                      </v-btn>
                    </v-toolbar>
                    <v-col>
                      <v-textarea
                        v-model="
                          entity.ShipmentInstruction.SICompanies.notifyParty
                            .CompanyName
                        "
                        outlined
                        label="Notify Party"
                        :height="80"
                        no-resize
                      ></v-textarea>
                      <v-textarea
                        v-model="
                          entity.ShipmentInstruction.SICompanies.notifyParty
                            .CompanyAddress
                        "
                        dense
                        hide-details
                        outlined
                        label="Address (Printed on B/L)"
                      ></v-textarea>
                    </v-col>
                  </v-col>
                </v-col>

                <!-- Carrier Details -->
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col>
                      <v-tabs v-model="selectedCarrierOptionTab">
                        <v-tab>Carrier</v-tab>
                        <v-tab>UCA</v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="selectedCarrierOptionTab">
                        <v-tab-item class="pa-4">
                          <v-text-field
                            v-model="
                              entity.ShipmentInstruction.SICompanies.Carrier
                                .CompanyName
                            "
                            outlined
                            dense
                            label="*Carrier"
                            disabled
                          ></v-text-field>
                          <v-text-field
                            v-model="
                              entity.ShipmentInstruction.SIReferences
                                .CarrierBookingNumber.CarrierBookingNumber_1
                                .ReferenceValue
                            "
                            outlined
                            dense
                            label="*Carrier Booking Number"
                          ></v-text-field>
                        </v-tab-item>
                        <v-tab-item class="pa-4">
                          <v-text-field
                            v-model="
                              entity.ShipmentInstruction.SICompanies.Carrier
                                .CompanyName
                            "
                            outlined
                            dense
                            label="*Carrier"
                            disabled
                          ></v-text-field>
                          <v-text-field
                            outlined
                            dense
                            label="*E-mail (max 2)"
                          ></v-text-field>
                          <v-text-field
                            v-model="
                              entity.ShipmentInstruction.SIReferences
                                .CarrierBookingNumber.CarrierBookingNumber_1
                                .ReferenceValue
                            "
                            outlined
                            dense
                            label="*Carrier Booking Number"
                            disabled
                          ></v-text-field>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-col>
                  </v-row>

                  <!-- References -->
                  <v-row>
                    <v-col>
                      <v-col>
                        <label>References</label>
                      </v-col>
                      <v-col>
                        <v-card
                          scrollable
                          style="height: 350px"
                          rounded="0"
                          dark
                          class="overflow-auto"
                        >
                          <v-card-text>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .ShippersReferenceNumber
                                  .ShippersReferenceNumber_1.ReferenceValue
                              "
                              outlined
                              dense
                              label="Shipper's Reference Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .ForwardersReferenceNumber
                                  .ForwardersReferenceNumber_1.ReferenceValue
                              "
                              outlined
                              dense
                              label="Forwarders's Reference Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .TransactionReferenceNumber
                                  .TransactionReferenceNumber_1.ReferenceValue
                              "
                              outlined
                              dense
                              label="Transaction Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .PurchaseOrderNumber.PurchaseOrderNumber_1
                                  .ReferenceValue
                              "
                              outlined
                              dense
                              label="Purchase Order Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .ContractReferenceNumber
                                  .ContractReferenceNumber_1.ReferenceValue
                              "
                              outlined
                              dense
                              label="Contract Reference Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .BLReferenceNumber.BLReferenceNumber_1
                                  .ReferenceValue
                              "
                              outlined
                              dense
                              label="B/L Reference Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .ExportLicenseNumber.ExportLicenseNumber_1
                                  .ReferenceValue
                              "
                              outlined
                              dense
                              label="Exporter’s Reference Number (RUC Number)"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .ConsigneeOrderNumber.ConsigneeOrderNumber_1
                                  .ReferenceValue
                              "
                              outlined
                              dense
                              label="Consignee Order Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .InvoiceReferenceNumber
                                  .InvoiceReferenceNumber_1.ReferenceValue
                              "
                              outlined
                              dense
                              label="Invoice Reference Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .LetterOfCreditReference
                                  .LetterOfCreditReference_1.ReferenceValue
                              "
                              outlined
                              dense
                              label="Letter of Credit Reference"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .CustomsHouseBrokerReference
                                  .CustomsHouseBrokerReference_1.ReferenceValue
                              "
                              outlined
                              dense
                              label="Customs House Broker Reference"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .FederalMaritimeCommision
                                  .FederalMaritimeCommision_1.ReferenceValue
                              "
                              outlined
                              dense
                              label="Government Reference or FMC Number"
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                entity.ShipmentInstruction.SIReferences
                                  .ExportLicenseNumber.ExportLicenseNumber_1
                                  .ReferenceValue
                              "
                              outlined
                              dense
                              label="Export License Number"
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Additional Parties -->
              <v-row>
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Additional Parties</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-col>
                            <v-col>
                              <label class="mb-6"
                                >Additional Notify Party 1</label
                              >
                            </v-col>
                            <v-col>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .notifyParty1.CompanyName
                                "
                                outlined
                                label="Additional Notify Party 1"
                                :height="80"
                                no-resize
                              ></v-textarea>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .notifyParty1.CompanyAddress
                                "
                                dense
                                hide-details
                                outlined
                                label="Address (Printed on B/L)"
                              ></v-textarea>
                            </v-col>
                          </v-col>
                          <v-col>
                            <v-col>
                              <label class="mb-6">Freight Payer</label>
                            </v-col>
                            <v-col>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .freightPayer.CompanyName
                                "
                                outlined
                                label="Freight Payer"
                                :height="80"
                                no-resize
                              ></v-textarea>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .freightPayer.CompanyAddress
                                "
                                dense
                                hide-details
                                outlined
                                label="Address (Printed on B/L)"
                              ></v-textarea>
                            </v-col>
                          </v-col>
                          <v-col>
                            <v-col>
                              <label class="mb-6">Importer</label>
                            </v-col>
                            <v-col>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .importer.CompanyName
                                "
                                outlined
                                label="Importer"
                                :height="80"
                                no-resize
                              ></v-textarea>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .importer.CompanyAddress
                                "
                                dense
                                hide-details
                                outlined
                                label="Address (Printed on B/L)"
                              ></v-textarea>
                            </v-col>
                          </v-col>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-col>
                            <v-col>
                              <label class="mb-6"
                                >Additional Notify Party 2</label
                              >
                            </v-col>
                            <v-col>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .notifyParty2.CompanyName
                                "
                                outlined
                                label="Additional Notify Party 2"
                                :height="80"
                                no-resize
                              ></v-textarea>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .notifyParty2.CompanyAddress
                                "
                                dense
                                hide-details
                                outlined
                                label="Address (Printed on B/L)"
                              ></v-textarea>
                            </v-col>
                          </v-col>
                          <v-col>
                            <v-col>
                              <label class="mb-6">Manufacturer/Supplier</label>
                            </v-col>
                            <v-col>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .supplier.CompanyName
                                "
                                outlined
                                label="Manufacturer/Supplier"
                                :height="80"
                                no-resize
                              ></v-textarea>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .supplier.CompanyAddress
                                "
                                dense
                                hide-details
                                outlined
                                label="Address (Printed on B/L)"
                              ></v-textarea>
                            </v-col>
                          </v-col>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-col>
                            <v-col>
                              <label class="mb-6">Contract Party</label>
                            </v-col>
                            <v-col>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .contractParty.CompanyName
                                "
                                outlined
                                label="Contract Party"
                                :height="80"
                                no-resize
                              ></v-textarea>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies
                                    .contractParty.CompanyAddress
                                "
                                dense
                                hide-details
                                outlined
                                label="Address (Printed on B/L)"
                              ></v-textarea>
                            </v-col>
                          </v-col>
                          <v-col>
                            <v-col>
                              <label class="mb-6">Consolidator/Stuffer</label>
                            </v-col>
                            <v-col>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies.stuffer
                                    .CompanyName
                                "
                                outlined
                                label="Consolidator/Stuffer"
                                :height="80"
                                no-resize
                              ></v-textarea>
                              <v-textarea
                                v-model="
                                  entity.ShipmentInstruction.SICompanies.stuffer
                                    .CompanyAddress
                                "
                                dense
                                hide-details
                                outlined
                                label="Address (Printed on B/L)"
                              ></v-textarea>
                            </v-col>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-sheet>

            <!-- Transport -->
            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col>
                  <h2>Transport</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-col>
                    <v-col>
                      <v-text-field
                        v-model="entity.ShipmentInstruction.Vessel"
                        dense
                        outlined
                        label="*Vessel"
                      ></v-text-field>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.OriginOfGoods
                            .LocationCity
                        "
                        dense
                        outlined
                        label="Origin of Goods"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.Origin.LocationCity
                        "
                        dense
                        outlined
                        label="Origin"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.PortOfLoad
                            .LocationCity
                        "
                        dense
                        outlined
                        label="*Port of Load"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.PortOfDischarge
                            .LocationCity
                        "
                        dense
                        outlined
                        label="*Port of Discharge"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.Destination
                            .LocationCity
                        "
                        dense
                        outlined
                        label="Destination"
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>

                <v-col cols="12" md="4">
                  <v-col>
                    <v-col>
                      <v-text-field
                        v-model="entity.ShipmentInstruction.Voyage"
                        dense
                        outlined
                        label="*Voyage"
                      ></v-text-field>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.OriginOfGoods
                            .PrintOnBLAs
                        "
                        dense
                        outlined
                        label="Print on B/L as"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.Origin.PrintOnBLAs
                        "
                        dense
                        outlined
                        label="Print on B/L as"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.PortOfLoad.PrintOnBLAs
                        "
                        dense
                        outlined
                        label="Print on B/L as"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.PortOfDischarge
                            .PrintOnBLAs
                        "
                        dense
                        outlined
                        label="Print on B/L as"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.Transportations
                            .Transportation_1.SILocations.Destination
                            .PrintOnBLAs
                        "
                        dense
                        outlined
                        label="Print on B/L as"
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>

                <v-col cols="12" md="4">
                  <v-col>
                    <v-col>
                      <!-- <v-text-field dense outlined label="IMO Number"></v-text-field> -->
                      <v-divider></v-divider>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="entity.ShipmentInstruction.MoveTypeCode"
                        dense
                        outlined
                        label="*Move Type"
                        item-text="description"
                        item-value="code"
                        @change="updateMoveTypeDescription"
                        :items="moveTypes"
                      ></v-select>
                      <v-text-field
                        v-model="entity.ShipmentInstruction.ShipmentTypeDesc"
                        dense
                        outlined
                        label="Shipment Type"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
              </v-row>
            </v-sheet>

            <!-- Particulars (Cargo/Containers) -->
            <v-sheet id="particularsWrapper" class="pa-4 my-8" rounded>
              <v-row>
                <v-col cols="8">
                  <h2>Particulars</h2>
                </v-col>
                <v-col cols="4" class="text-end">
                  <!-- <v-btn icon @click="toggleParticularsFullscreen">
                    <v-icon>{{ particularsFullscreen ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
                  </v-btn> -->
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col>
                  <v-checkbox v-model="isSingleCargo" label="I’m shipping only one cargo in this shipment"></v-checkbox>
                </v-col>
              </v-row> -->

              <v-expansion-panels flat dense>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >Cargo & Containers</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-sheet
                      height="80vh"
                      class="overflow-y-auto overflow-x-hidden pb-0"
                    >
                      <v-row
                        v-for="(container, i) of entity.ShipmentInstruction
                          .Containers"
                        :key="i"
                      >
                        <v-col cols="12" md="3">
                          <div class="py-4">
                            {{ `Container ${i + 1}` }}
                            <v-divider class="mt-1"></v-divider>
                          </div>

                          <v-text-field
                            v-model="container.ContainerNumber"
                            dense
                            outlined
                            label="*Container Number"
                          ></v-text-field>

                          <v-text-field
                            v-model="container.ContainerDescription"
                            dense
                            outlined
                            label="*Container Type"
                          ></v-text-field>

                          <v-select
                            label="Product Type"
                            :disabled="false"
                            v-model="container.productType"
                            @change="updateContainerCargoDescription(container)"
                            dense
                            outlined
                            :items="productTypes"
                            clearable
                          ></v-select>

                          <v-btn
                            block
                            small
                            class="mb-6"
                            @click="
                              viewDialog(container, i, 'reeferSettingsDialog')
                            "
                            >View Reefer Settings</v-btn
                          >

                          <v-text-field
                            disabled
                            v-model="container.ContainerSupplierTypeDesc"
                            dense
                            outlined
                            label="Container Supplier"
                          ></v-text-field>

                          <v-row dense>
                            <v-col cols="9">
                              <v-text-field
                                v-model="container.ContainerTareWt"
                                dense
                                outlined
                                label="Container Tare Weight"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                v-model="container.ContainerTareWtUomDesc"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-text-field
                            v-model="container.WoodDeclarationTypeId"
                            dense
                            outlined
                            label="Wood Declaration"
                          ></v-text-field>

                          <v-text-field
                            v-model="
                              container.ContainerSeals.ContainerSeal_1
                                .SealNumber
                            "
                            dense
                            outlined
                            label="Carrier Seal Number(s)"
                          ></v-text-field>
                          <!-- TODO: Figure out where the Shipper Seal Numbers are mapped to -->
                          <v-text-field
                            dense
                            outlined
                            label="Shipper Seal Number(s)"
                          ></v-text-field>

                          <v-btn
                            block
                            small
                            @click="
                              viewDialog(container, i, 'containerDetailsDialog')
                            "
                            >View Container Details</v-btn
                          >
                        </v-col>

                        <v-col cols="12" md="6">
                          <div class="py-4">
                            {{ `Cargo ${i + 1}` }}
                            <v-divider class="mt-1"></v-divider>
                          </div>

                          <v-row>
                            <v-col cols="4">
                              <v-text-field
                                v-model="
                                  container.ContainerLineItems[0].PackageCount
                                "
                                @change="updateControl('TotalPackageCount')"
                                dense
                                outlined
                                label="Package Count/Type (Outermost)"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="8">
                              <v-autocomplete
                                v-model="
                                  container.ContainerLineItems[0]
                                    .PackageTypeCode
                                "
                                dense
                                outlined
                                label="Package Type"
                                :items="packageTypes"
                                item-text="description"
                                item-value="code"
                                @change="
                                  updatePackageTypeCode(
                                    container.ContainerLineItems[0]
                                  )
                                "
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <template v-if="i === 0 || (i > 0 && !isSingleCargo)">
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="
                                    container.ContainerLineItems[0]
                                      .PackageTypeDescriptionPrint
                                  "
                                  dense
                                  outlined
                                  label="Print on B/L as"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  :value="
                                    getHSCode(container.ContainerLineItems[0])
                                  "
                                  dense
                                  outlined
                                  label="HS Code"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="
                                    container.ContainerLineItems[0]
                                      .PackageTypeDescription
                                  "
                                  dense
                                  outlined
                                  label="Schedule B Number"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <v-textarea
                                  v-model="
                                    container.ContainerLineItems[0]
                                      .CargoDescription
                                  "
                                  dense
                                  outlined
                                  label="*Cargo Description"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="
                                    container.ContainerLineItems[0]
                                      .MarksAndNumbers
                                  "
                                  dense
                                  outlined
                                  label="NCM Code(s)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="
                                    container.ContainerLineItems[0]
                                      .MarksAndNumbers
                                  "
                                  dense
                                  outlined
                                  label="Marks & Numbers "
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="12">
                                <v-btn
                                  block
                                  small
                                  class="mb-4"
                                  @click="
                                    viewDialog(
                                      container,
                                      i,
                                      'cargoHazmatDetailsDialog'
                                    )
                                  "
                                  >View Hazmat Details</v-btn
                                >
                                <v-btn
                                  block
                                  small
                                  @click="
                                    viewDialog(
                                      container,
                                      i,
                                      'cargoExportDetailsDialog'
                                    )
                                  "
                                  >View Export Declaration/References</v-btn
                                >
                              </v-col>
                            </v-row>
                          </template>
                        </v-col>

                        <v-col cols="12" md="3">
                          <div class="py-4">
                            Cargo Gross Weight & Volume
                            <v-divider class="mt-1"></v-divider>
                          </div>

                          <v-row>
                            <v-col cols="8">
                              <v-text-field
                                v-model="
                                  container.ContainerLineItems[0]
                                    .GrossCargoWeight
                                "
                                @change="updateControl('TotalWeight')"
                                dense
                                outlined
                                label="*Cargo Gross Weight (Cargo + Packaging)"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                disabled
                                v-model="
                                  container.ContainerLineItems[0]
                                    .CargoWeightUomDesc
                                "
                                dense
                                outlined
                                label="Metric"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="8">
                              <v-text-field
                                v-model="
                                  container.ContainerLineItems[0]
                                    .GrossCargoVolume
                                "
                                @change="updateControl('TotalVolume')"
                                dense
                                outlined
                                label="*Cargo Gross Volume (Cargo + Packaging)"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                disabled
                                v-model="
                                  container.ContainerLineItems[0]
                                    .CargoVolumeUomDesc
                                "
                                dense
                                outlined
                                label="Metric"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-sheet>

            <!-- Control Totals -->
            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col>
                  <h2>Control Totals</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    disabled
                    v-model="
                      entity.ShipmentInstruction.ShipmentSummary
                        .TotalContainerCount
                    "
                    dense
                    outlined
                    label="Total Number of Containers"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    disabled
                    v-model="
                      entity.ShipmentInstruction.ShipmentSummary
                        .TotalPackageCount
                    "
                    dense
                    outlined
                    label="Total Number of Packages"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="
                      entity.ShipmentInstruction.ShipmentSummary.TotalWeight
                    "
                    dense
                    outlined
                    label="Total Shipment Weight"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="
                      entity.ShipmentInstruction.ShipmentSummary.TotalVolume
                    "
                    dense
                    outlined
                    label="Total Shipment Volume"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-sheet>

            <!-- Customs Compliance -->
            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col>
                  <h2>Customs Compliance</h2>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <label>Government Tax IDs</label>
                </v-col>

                <v-col>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.shipper.Taxid
                        "
                        dense
                        outlined
                        label="Shipper"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.consignee.Taxid
                        "
                        dense
                        outlined
                        label="Consignee"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.notifyParty
                            .Taxid
                        "
                        dense
                        outlined
                        label="Notify Party"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider class="my-2"></v-divider>

              <v-row>
                <v-col cols="12">
                  <label
                    >House Bill Filing Information (US, Canada, Mexico, Puerto
                    Rico Customs Filing)</label
                  >
                </v-col>

                <v-col>
                  <v-radio-group dense v-model="selectedFilingOption">
                    <v-radio label="N/A" :value="FILING_OPTIONS.NA"></v-radio>
                    <v-radio
                      label="Filed by NVOCC"
                      :value="FILING_OPTIONS.NVOCC"
                    ></v-radio>
                    <v-radio
                      label="Filed by Carrier"
                      :value="FILING_OPTIONS.CARRIER"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-divider class="my-2"></v-divider>

              <v-row>
                <v-col cols="12">
                  <label
                    >Cargo Identification Numbers (India Sea Cargo Manifest,
                    Egypt ACI)</label
                  >
                </v-col>

                <v-col>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SIReferences
                            .PrimaryCargoIdentificationNumber
                            .PrimaryCargoIdentificationNumber_1.ReferenceValue
                        "
                        dense
                        outlined
                        label="PCIN"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SIReferences
                            .CargoSummaryNotification.CargoSummaryNotification_1
                            .ReferenceValue
                        "
                        dense
                        outlined
                        label="CSN"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SIReferences
                            .MasterCargoIdentificationNumber
                            .MasterCargoIdentificationNumber_1.ReferenceValue
                        "
                        dense
                        outlined
                        label="ACID Number, MCIN"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>

            <!-- Shipper's Declared Value -->
            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col>
                  <h2>Shipper's Declared Value</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="
                      entity.ShipmentInstruction.DeclaredValueCurrencyDesc
                    "
                    dense
                    outlined
                    label="Currency Type"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="entity.ShipmentInstruction.DeclaredValue"
                    dense
                    outlined
                    label="Shipper’s Declared Value"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-sheet>

            <!-- Freight Charges -->
            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col>
                  <h2>Freight Charges</h2>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col cols="12">
                  <v-checkbox dense hide-details label="All Charges" v-model="selectedChargesMethod" :value="CHARGE_METHODS.ALL"></v-checkbox>
                </v-col> -->
                <v-col v-if="selectedChargesMethod === CHARGE_METHODS.ALL">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-select
                        :items="freightTerms"
                        dense
                        outlined
                        label="*Freight Term"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        :items="partyTypes"
                        dense
                        outlined
                        label="*Payer"
                        item-text="label"
                        item-value="label"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        dense
                        outlined
                        label="Payment Location"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col cols="12">
                  <v-checkbox dense hide-details label="Individual Charges" v-model="selectedChargesMethod" :value="CHARGE_METHODS.INDIVIDUAL"></v-checkbox>
                </v-col> -->
                <v-col
                  v-if="selectedChargesMethod === CHARGE_METHODS.INDIVIDUAL"
                >
                  <v-row v-for="(item, i) in entity.chargesArray" :key="i">
                    <v-col cols="12" md="3">
                      <v-text-field
                        disabled
                        dense
                        outlined
                        label="*Charge Type"
                        v-model="item.ChargeTypeDesc"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        :items="freightTerms"
                        dense
                        outlined
                        label="*Freight Term"
                        v-model="item.FreightTermDesc"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        :items="partyTypes"
                        dense
                        outlined
                        label="*Payer"
                        item-text="label"
                        item-value="label"
                        v-model="item.PartyName"
                        @change="setPartyTypeCode(item)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        dense
                        outlined
                        label="Payment Location"
                        v-model="item.PaymentLocation"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>

            <!-- Documentation -->
            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col>
                  <h2>Documentation</h2>
                </v-col>
              </v-row>

              <!-- Clauses -->
              <v-row>
                <v-col cols="12" md="8">
                  <label>Clauses</label>
                  <v-list outlined rounded height="200" class="overflow-auto">
                    <v-list-item v-for="(item, i) of clauseList" :key="i">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="4">
                  <label>User defined clauses</label>
                  <v-textarea
                    v-model="
                      entity.ShipmentInstruction.SIClauses.Freetext_1
                        .ClauseDescription
                    "
                    hide-details
                    outlined
                    height="200"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="
                      entity.ShipmentInstruction.SILocations.BLPlaceOfIssue
                        .GeographyAreaId
                    "
                    :items="cityLocations"
                    label="B/L Release Office"
                    outlined
                    :disabled="false"
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="
                      entity.ShipmentInstruction.SILocations.BLPlaceOfIssue
                        .PrintOnBLAs
                    "
                    dense
                    outlined
                    label="Print on B/L as"
                    :disabled="false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="
                      entity.ShipmentInstruction.SILocations.BLPlaceOfIssue
                        .RequestedDateOfIssue
                    "
                    dense
                    outlined
                    label="Requested Date of Issue"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- B/L Print Instructions -->
              <v-row>
                <v-col>
                  <h4>B/L Print Instructions</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-col>
                    <v-btn-toggle
                      v-model="entity.selectedInstructionMethod"
                      tile
                      group
                      color="orange"
                    >
                      <v-btn
                        @click="setCompanyDocuments('SEAWAY')"
                        :value="INSTRUCTION_METHODS.SEAWAY"
                      >
                        Seaway/Express
                      </v-btn>

                      <v-btn
                        @click="setCompanyDocuments('ORIGINAL')"
                        :value="INSTRUCTION_METHODS.ORIGINAL"
                      >
                        Original
                      </v-btn>

                      <v-btn
                        @click="setCompanyDocuments('HOUSE_BILL')"
                        :value="INSTRUCTION_METHODS.HOUSE_BILL"
                      >
                        House Bill
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col>
                    <template
                      v-if="
                        entity.selectedInstructionMethod ===
                        INSTRUCTION_METHODS.SEAWAY
                      "
                    >
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.Requestor
                            .CompanyDocuments.SeaWaybillDocumentFreighted
                            .NumberOfDocuments
                        "
                        dense
                        outlined
                        label="Freighted"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.Requestor
                            .CompanyDocuments.SeaWaybillDocumentNonFreighted
                            .NumberOfDocuments
                        "
                        dense
                        outlined
                        label="Unfreighted"
                      ></v-text-field>
                    </template>
                    <template
                      v-if="
                        entity.selectedInstructionMethod ===
                        INSTRUCTION_METHODS.ORIGINAL
                      "
                    >
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.Requestor
                            .CompanyDocuments.OriginalDocumentFreighted
                            .NumberOfDocuments
                        "
                        dense
                        outlined
                        label="Freighted"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.Requestor
                            .CompanyDocuments.OriginalDocumentNonFreighted
                            .NumberOfDocuments
                        "
                        dense
                        outlined
                        label="Unfreighted"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.Requestor
                            .CompanyDocuments.NonNegotiableDocumentFreighted
                            .NumberOfDocuments
                        "
                        dense
                        outlined
                        label="Non-Negotiable Freighted"
                      ></v-text-field>
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.Requestor
                            .CompanyDocuments.NonNegotiableDocumentNonFreighted
                            .NumberOfDocuments
                        "
                        dense
                        outlined
                        label="Non-Negotiable Unfreighted"
                      ></v-text-field>
                    </template>
                    <template
                      v-if="
                        entity.selectedInstructionMethod ===
                        INSTRUCTION_METHODS.HOUSE_BILL
                      "
                    >
                      <v-text-field
                        v-model="
                          entity.ShipmentInstruction.SICompanies.Requestor
                            .CompanyDocuments.HouseBillOfLading.MasterNumber
                        "
                        dense
                        outlined
                        label="House Bill Number"
                      ></v-text-field>
                    </template>
                  </v-col>
                </v-col>
                <v-col cols="12" md="6">
                  <label>B/L Comments</label>
                  <v-textarea
                    v-model="entity.ShipmentInstruction.BLRemarks"
                    hide-details
                    outlined
                    :disabled="false"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-sheet>

            <!-- Notification Emails -->
            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col>
                  <h2>Notification Emails</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-col>
                    <label>SI Requestor Emails</label>
                    <v-text-field
                      v-model="
                        entity.ShipmentInstruction.SICompanies.Requestor
                          .CompanyContacts.CompanyContact_1
                          .CompanyCommunications.Email_1.CommunicationDetails
                      "
                      dense
                      outlined
                      label="Enter Emails"
                      hide-details
                      disabled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="12" md="6">
                  <v-col>
                    <label>Partner Notification Emails (max 6)</label>
                    <v-text-field
                      :value="selectedPartnerEmailList"
                      dense
                      outlined
                      label="Enter Emails"
                      hide-details
                      class="mt-2"
                    >
                      <template v-slot:append-outer>
                        <v-btn small outlined @click="showPartnerEmails"
                          >Select</v-btn
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-col>
              </v-row>
            </v-sheet>

            <v-sheet class="pa-4 my-8" rounded>
              <v-row>
                <v-col cols="12">
                  <v-col>
                    <v-text-field
                      v-model="entity.ShipmentInstruction.SiName"
                      dense
                      hide-details
                      outlined
                      label="*SI Name"
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
            </v-sheet>
          </v-form>
        </template>
        <template v-else>
          <div class="text-center pa-4">No shipping instruction data</div>
          <div class="text-center pa-4">
            <v-btn outlined @click="viewDebugDialog">View Debug Data</v-btn>
          </div>
        </template>
      </v-container>

      <!-- <v-card-actions dark>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Close</v-btn>
      </v-card-actions> -->
    </v-card>

    <!-- Reefer Settings Dialog -->
    <v-dialog v-model="reeferSettingsDialog" width="800">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>
            {{ `Container ${selectedContainerIndex + 1}` }} - Reefer Settings
          </v-toolbar-title>
          <v-icon
            class="ml-auto"
            @click="closeReeferSettings(selectedContainer)"
            >close</v-icon
          >
        </v-toolbar>
        <v-card-text v-if="selectedContainer">
          <v-row class="mb-4">
            <v-col cols="12">
              <div>*Non-Active Reefer (or) Temperature is required.</div>
            </v-col>
            <v-col cols="12">
              <v-btn-toggle
                v-model="
                  selectedContainer.ContainerPopupCollections
                    .ContainerReeferPopup.NonActiveReefer
                "
                tile
                group
                block
              >
                <v-btn :value="REEFER_TYPE.ACTIVE"> *Active Reefer </v-btn>

                <v-btn :value="REEFER_TYPE.NON_ACTIVE">
                  *Non-Active Reefer
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="
                      selectedContainer.ContainerPopupCollections
                        .ContainerReeferPopup.ReeferTemperature
                    "
                    dense
                    hide-details
                    outlined
                    label="*Temperature"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="
                      selectedContainer.ContainerPopupCollections
                        .ContainerReeferPopup.ReeferTemperatureUomDesc
                    "
                    dense
                    hide-details
                    outlined
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="
                      selectedContainer.ContainerPopupCollections
                        .ContainerReeferPopup.ReeferAirFlow
                    "
                    dense
                    hide-details
                    outlined
                    label="*Air Flow"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="
                      selectedContainer.ContainerPopupCollections
                        .ContainerReeferPopup.ReeferAirFlowUomDesc
                    "
                    dense
                    hide-details
                    outlined
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="
                  selectedContainer.ContainerPopupCollections
                    .ContainerReeferPopup.ReeferComments
                "
                hide-details
                outlined
                label="Temperature Control Instructions"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Conatiner Details Dialog -->
    <v-dialog v-model="containerDetailsDialog" width="800">
      <v-card>
        <v-card-title>
          {{ `Container ${selectedContainerIndex + 1}` }} - Details
          <v-icon class="ml-auto" @click="containerDetailsDialog = false"
            >close</v-icon
          >
        </v-card-title>
        <v-card-text v-if="selectedContainer">
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="
                  selectedContainer.ContainerPopupCollections
                    .ContainerDetailPopup.TerminalOperatorSealNumber
                "
                dense
                outlined
                label="Terminal Operator Seal Number"
              ></v-text-field>
              <v-text-field
                v-model="
                  selectedContainer.ContainerPopupCollections
                    .ContainerDetailPopup.VeterinarySealNumber
                "
                dense
                outlined
                label="Veterinary Seal Number"
              ></v-text-field>
              <v-text-field
                v-model="
                  selectedContainer.ContainerPopupCollections
                    .ContainerDetailPopup.CustomsSealNumber
                "
                dense
                outlined
                label="Customs Seal Number"
              ></v-text-field>
              <v-text-field
                v-model="
                  selectedContainer.ContainerPopupCollections
                    .ContainerDetailPopup.CustomsSealNumber
                "
                dense
                outlined
                label="Customs Release Code"
              ></v-text-field>
              <v-text-field
                v-model="
                  selectedContainer.ContainerPopupCollections
                    .ContainerDetailPopup.ContainerStuffingLocation
                "
                dense
                outlined
                label="Container Stuffing Location"
              ></v-text-field>
              <v-textarea
                v-model="
                  selectedContainer.ContainerPopupCollections
                    .ContainerDetailPopup.ContainerComments
                "
                hide-details
                outlined
                label="Container Comments"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Cargo Export Details Dialog -->
    <v-dialog v-model="cargoExportDetailsDialog" width="800">
      <v-card>
        <v-card-title>
          {{ `Cargo ${selectedContainerIndex + 1}` }} - Details (Export
          Declarations/References)
          <v-icon class="ml-auto" @click="cargoExportDetailsDialog = false"
            >close</v-icon
          >
        </v-card-title>
        <v-card-text v-if="selectedContainer">
          <v-row dense>
            <v-col cols="12" md="6" class="mt-4">
              <div>References</div>
              <v-divider class="mb-4"></v-divider>

              <v-text-field
                dense
                outlined
                label="Consignee Order Number"
              ></v-text-field>
              <v-text-field
                dense
                outlined
                label="Contract Number"
              ></v-text-field>
              <v-text-field
                dense
                outlined
                label="Purchase Order Number"
              ></v-text-field>
              <v-text-field dense outlined label="SKU Number"></v-text-field>
              <v-text-field dense outlined label="TLI/SCN"></v-text-field>
              <v-text-field
                dense
                outlined
                label="Vehicle Identification Number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div>
                Cargo Identification Numbers (India Sea Cargo Manifest
                Regulations)
              </div>
              <v-divider class="mb-4"></v-divider>

              <v-text-field dense outlined label="PCIN"></v-text-field>
              <v-text-field dense outlined label="CSN"></v-text-field>
              <v-text-field dense outlined label="MCIN"></v-text-field>

              <div>
                United States / European Union Export Declaration Details
              </div>
              <v-divider class="mb-4"></v-divider>

              <v-text-field
                dense
                outlined
                label="Transaction Number (ITN/MRN)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Cargo Hazmat Details Dialog -->
    <v-dialog v-model="cargoHazmatDetailsDialog" width="800">
      <v-card>
        <v-card-title>
          {{ `Cargo ${selectedContainerIndex + 1}` }} - Hazmat Details
          <v-icon class="ml-auto" @click="cargoHazmatDetailsDialog = false"
            >close</v-icon
          >
        </v-card-title>
        <v-card-text v-if="selectedContainer">
          <v-row dense>
            <v-col class="mt-4">
              <div>Hazmat Detail List</div>
              <v-divider class="mb-4"></v-divider>

              <v-data-table
                :items="getCargoHazmatItems(selectedContainer)"
                :headers="hazmatListHeaders"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Debug Dialog -->
    <v-dialog v-model="debugDialog" fullscreen>
      <v-card flat rounded="0">
        <v-card-title>
          SI Data
          <v-icon class="ml-auto" @click="debugDialog = false">close</v-icon>
        </v-card-title>
        <v-tabs v-model="debugTabs">
          <v-tab>Payload</v-tab>
          <v-tab>Validations</v-tab>
        </v-tabs>
        <v-tabs-items v-model="debugTabs">
          <v-tab-item>
            <vue-json-pretty
              :data="resultData"
              :showLine="false"
              :virtual="true"
              :showIcon="true"
              :height="jsonViewportHeight"
              :collapsedNodeLength="1"
              :deep="1"
            />
          </v-tab-item>
          <v-tab-item>
            <vue-json-pretty
              v-if="resultData && resultData.shippingInstruction"
              :data="resultData.shippingInstruction.validationErrors"
              :showLine="false"
              :virtual="true"
              :showIcon="true"
              :height="jsonViewportHeight"
            />
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions dark>
          <v-spacer></v-spacer>
          <v-btn text @click="debugDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Partner Notifications Dialog -->
    <v-dialog v-model="partnerUserDialog" width="500px" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Select Partner Notification Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="partnerUserDialog = false">OK</v-btn>
          <v-btn text @click="partnerUserDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <div>Selected: {{ selectedPartnerEmails.length }}/6</div>

          <v-text-field
            v-model="partnerEmailSearch"
            outlined
            placeholder="Search"
            prepend-inner-icon="search"
            clearable
          ></v-text-field>

          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filteredUsers.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey">No users found</span>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="user in filteredUsers"
              :key="user.id"
              @click="addPartnerEmail(user.user.emailAddress)"
            >
              <v-list-item-action>
                <v-avatar
                  :color="user.user.avatar ? 'white' : 'secondary'"
                  size="36"
                >
                  <v-img
                    v-if="user.user.avatar"
                    referrerpolicy="no-referrer"
                    :src="user.user.avatar"
                    contain
                  ></v-img>
                  <h2 v-else style="color: white; font-weight: normal">
                    {{ user.user.firstname.charAt(0) }}
                  </h2>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.user.firstname }} {{ user.user.surname }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  v-if="selectedPartnerEmails.includes(user.user.emailAddress)"
                  color="green"
                  >check_circle_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Submit Prompt Dialog -->
    <v-dialog v-model="submitDraftDialog" width="800" persistent>
      <v-card flat rounded="0">
        <v-card-title>
          Are you sure?
          <v-icon class="ml-auto" @click="submitDraftDialog = false"
            >close</v-icon
          >
        </v-card-title>
        <v-card-text>
          Are you sure you want to submit a draft shipping instruction to
          Inttra?<br />
          <!-- This can take up to 30 seconds. -->
        </v-card-text>

        <v-card-actions dark>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="submitDraftShippingInstruction"
            :loading="loadingData"
            >Yes</v-btn
          >
          <v-btn text @click="submitDraftDialog = false" :loading="loadingData"
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="contactDetailDialog" width="400px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Contact Details </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="setContactDetails()">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="contactDetailDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Contact Name"
            prepend-inner-icon="person"
            v-model="contactDetail.name"
            outlined
            clearable
          >
          </v-text-field>
          <v-text-field
            label="Phone"
            prepend-inner-icon="call"
            v-model="contactDetail.phoneNumber"
            outlined
            clearable
          >
          </v-text-field>
          <v-text-field
            label="Email Address"
            prepend-inner-icon="email"
            v-model="contactDetail.emailAddress"
            outlined
            clearable
          >
          </v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  SI_INTEGRATION_CODES,
  SI_TEST_USERS_IDS,
  SI_FORM_ACTION_TYPES,
} from "@/utils/constants";
import cities from "@/utils/integrations/Inttra/inntraCityList.json";

const CHARGE_METHODS = {
  ALL: 1,
  INDIVIDUAL: 2,
};

const INSTRUCTION_METHODS = {
  SEAWAY: 1,
  ORIGINAL: 2,
  HOUSE_BILL: 3,
};

const FILING_OPTIONS = {
  NA: 1,
  NVOCC: 2,
  CARRIER: 3,
};

const REEFER_TYPE = {
  ACTIVE: "false",
  NON_ACTIVE: "true",
};

export default {
  props: {
    shipmentId: {
      type: String,
    },

    actionType: {
      type: Number,
      default: SI_FORM_ACTION_TYPES.INTERNAL,
    },

    actionInput: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      contactDetailDialog: false,
      contactDetail: {
        name: "",
        phoneNumber: "",
        emailAddress: "",
      },
      loadingData: false,
      loadingOrgUsers: false,
      particularsFullscreen: false,
      resultData: null,
      selectedCarrierOptionTab: 0,
      selectedChargesMethod: CHARGE_METHODS.INDIVIDUAL,
      selectedInstructionMethod: INSTRUCTION_METHODS.SEAWAY,
      selectedFilingOption: FILING_OPTIONS.NA,
      selectedReeferType: REEFER_TYPE.ACTIVE,
      selectedContainer: null,
      selectedContainerIndex: null,
      selectedPartnerEmails: [],
      orgUsers: [],
      partnerEmailSearch: null,
      reeferSettingsDialog: false,
      containerDetailsDialog: false,
      cargoExportDetailsDialog: false,
      cargoHazmatDetailsDialog: false,
      submitDraftDialog: false,
      partnerUserDialog: false,
      debugDialog: false,
      debugViewEnabled: true,
      jsonDebugHeight: 400,
      debugTabs: 0,
      freightTerms: ["Prepaid", "Collect", "PayableElsewhere"],
      moveTypes: [
        {
          code: "1",
          description: "Port,Ramp,CY/CFS to Port,Ramp,CY/CFS",
        },
        {
          code: "4",
          description: "Door to Door",
        },
        {
          code: "2",
          description: "Door to Port, Ramp, or CY",
        },
        {
          code: "3",
          description: "Port, Ramp, or CY to Door",
        },
      ],
      hazmatListHeaders: [
        {
          text: "UNDG #",
          value: "",
          sortable: false,
        },
        {
          text: "IMO Class",
          value: "",
          sortable: false,
        },
        {
          text: "Proper Shipping Name",
          value: "",
          sortable: false,
        },
        {
          text: "Flash Point",
          value: "",
          sortable: false,
        },
      ],
      // packageTypes: [{
      //   code: '95',
      //   description: 'Carton'
      // },{
      //   code: '39',
      //   description: 'Bin'
      // },{
      //   code: '284',
      //   description: 'Package'
      // }],
      packageTypes: [
        { description: "Aerosol", code: "1" },
        { description: "Ammo Pack", code: "2" },
        { description: "Ampoule,non-protected", code: "3" },
        { description: "Ampoule,protected", code: "4" },
        { description: "Atomizer", code: "5" },
        { description: "Bag", code: "6" },
        { description: "Bag, large", code: "7" },
        { description: "Bag, paper", code: "8" },
        { description: "Bag, paper, multi-wall", code: "9" },
        { description: "Bag,paper,multiwall,H2O resis", code: "10" },
        { description: "Bag, plastic", code: "11" },
        { description: "Bag, plastics film", code: "12" },
        { description: "Bag, textile", code: "13" },
        { description: "Bag, textile, sift proof", code: "14" },
        { description: "Bag, textile, water resistant", code: "15" },
        { description: "Bag,text,no inner coat/liner", code: "16" },
        { description: "Bag, woven plastic", code: "17" },
        { description: "Bag, woven plastic, sift proof", code: "18" },
        { description: "Bag, woven plastic, water resistant", code: "19" },
        { description: "Bag,woven plstc,no inner coat/lin", code: "20" },
        { description: "Bale", code: "21" },
        { description: "Bale,compressed", code: "22" },
        { description: "Bale,non-compressed", code: "23" },
        { description: "Balloon,non-protected", code: "24" },
        { description: "Balloon,protected", code: "25" },
        { description: "Bar", code: "26" },
        { description: "Barge", code: "27" },
        { description: "Barrel", code: "28" },
        { description: "Barrel, wooden", code: "29" },
        { description: "Barrel, wooden, bung type", code: "30" },
        { description: "Barrel, wooden, removable head", code: "31" },
        { description: "Bars,inbundle/bunch/truss", code: "32" },
        { description: "Basin", code: "33" },
        { description: "Basket or hamper", code: "34" },
        { description: "Basket, with handle, cardboard", code: "35" },
        { description: "Basket, with handle, plastic", code: "36" },
        { description: "Basket, with handle, wooden", code: "37" },
        { description: "Beam", code: "38" },
        { description: "Bin", code: "39" },
        { description: "Bing Chest", code: "40" },
        { description: "Blocks", code: "41" },
        { description: "Board", code: "42" },
        { description: "Board,inbundle/bunch/truss", code: "43" },
        { description: "Bobbin", code: "44" },
        { description: "Bolt", code: "45" },
        { description: "Bottle", code: "46" },
        { description: "Bottle, Gas", code: "47" },
        { description: "Bottle,non-protected,bulbous", code: "48" },
        { description: "Bottle,non-protected,cylindrical", code: "49" },
        { description: "Bottle,protectedbulbous", code: "50" },
        { description: "Bottle,protectedcylindrical", code: "51" },
        { description: "Bottlecrate,bottlerack", code: "52" },
        { description: "Box", code: "53" },
        { description: "Box, aluminium", code: "54" },
        { description: "Box,CHEP,Eurobox", code: "55" },
        { description: "Box, fibreboard", code: "56" },
        { description: "Box, for liquids", code: "57" },
        { description: "Box, natural wood", code: "58" },
        { description: "Box, plastic", code: "59" },
        { description: "Box, plastic, expanded", code: "60" },
        { description: "Box, plastic, solid", code: "61" },
        { description: "Box, plywood", code: "62" },
        { description: "Box, reconstituted wood", code: "63" },
        { description: "Box, steel", code: "64" },
        { description: "Box, with inner container", code: "65" },
        { description: "Box, wooden, natural wood, ordinary", code: "66" },
        { description: "Box,woodn,nat. wood,siftproof wall", code: "67" },
        { description: "Bucket", code: "68" },
        { description: "Bulk", code: "69" },
        { description: "Bulk Bag", code: "70" },
        { description: "Bulk,gas(at1031mbarand15 C)", code: "71" },
        { description: "Bulk,liq gas", code: "72" },
        { description: "Bulk,sol,fine partcls-powder", code: "73" },
        { description: "Bulk,solid,granular particles-grain", code: "74" },
        { description: "Bulk,solid,granular partcls-nodules", code: "75" },
        { description: "Bunch", code: "76" },
        { description: "Bundle", code: "77" },
        { description: "Butt", code: "78" },
        { description: "Cabinet", code: "79" },
        { description: "Cage", code: "80" },
        { description: "Cage,CHEP", code: "81" },
        { description: "Cage, roll", code: "82" },
        { description: "Can", code: "83" },
        { description: "Can Case", code: "84" },
        { description: "Can, with handle and spout", code: "85" },
        { description: "Can,cylindrical", code: "86" },
        { description: "Can,rectangular", code: "87" },
        { description: "Canister", code: "88" },
        { description: "Canvas", code: "89" },
        { description: "Car Load, Rail", code: "90" },
        { description: "Carboy", code: "91" },
        { description: "Carboy,non-protected", code: "92" },
        { description: "Carboy,protected", code: "93" },
        { description: "Carrier", code: "94" },
        { description: "Carton", code: "95" },
        { description: "Case", code: "96" },
        { description: "Case, isothermic", code: "97" },
        { description: "Case, skeleton", code: "98" },
        { description: "Case, with pallet base", code: "99" },
        { description: "Case, with pallet base, cardboard", code: "100" },
        { description: "Case, with pallet base, metal", code: "101" },
        { description: "Case, with pallet base, plastic", code: "102" },
        { description: "Case, with pallet base, wooden", code: "103" },
        { description: "Cask", code: "104" },
        { description: "Cheeses", code: "105" },
        { description: "Chest", code: "106" },
        { description: "Churn", code: "107" },
        { description: "Coffer", code: "108" },
        { description: "Coffin", code: "109" },
        { description: "Coil", code: "110" },
        { description: "Cmp Pack,glass rcpt", code: "111" },
        { description: "Cmp Pack,glass rcpt", code: "112" },
        { description: "Cmp Pack,glass rcpt in alum drum", code: "113" },
        { description: "Cmp Pack,glass rcpt,exnd plstc pck", code: "114" },
        { description: "Cmp Pack,glass rcpt in fibre drum", code: "115" },
        { description: "Cmp Pack,glass rcpt in fibreb box", code: "116" },
        { description: "Cmp Pack,glass rcpt in plywd drum", code: "117" },
        { description: "Cmp Pack,glass rcpt, sld plstc pck", code: "118" },
        { description: "Cmp Pack,glass rcpt,steel crt box", code: "119" },
        { description: "Cmp Pack,glass rcpt in steel drum", code: "120" },
        { description: "Cmp Pack,glass rcpt,wickwork hamp", code: "121" },
        { description: "Cmp Pack,glass rcpt in wooden box", code: "122" },
        { description: "Cmp Pack,plastic rcpt", code: "123" },
        { description: "Cmp Pack,plastic rcpt, alum crate", code: "124" },
        { description: "Cmp Pack,plastic rcpt in alum drum", code: "125" },
        { description: "Cmp Pack,plastic rcpt,fibre drum", code: "126" },
        { description: "Cmp Pack,plastic rcpt in fireb box", code: "127" },
        { description: "Cmp Pack,plastic rcpt,plstc drum", code: "128" },
        { description: "Cmp Pack,plastic rcpt in plywd box", code: "129" },
        { description: "Cmp Pack,plastic rcpt,plywd drum", code: "130" },
        { description: "Cmp Pack,plstc rcpt,solid plstc box", code: "131" },
        { description: "Cmp Pack,plstc rcpt,steel crate box", code: "132" },
        { description: "Cmp Pack,plstc rcpt,steel drum", code: "133" },
        { description: "Cmp Pack,plstc rcpt,in wooden box", code: "134" },
        { description: "Cones", code: "135" },
        { description: "CONEX - Container Express", code: "136" },
        { description: "ctr,not specif transport equipment", code: "137" },
        { description: "Container, Commercial Highway Lift", code: "138" },
        {
          description: "Container, MAC-ISO Light Weight 8x8x20 Foot Air",
          code: "139",
        },
        { description: "Container, Navy Cargo Transporter", code: "140" },
        { description: "Containers of Bulk Cargo", code: "141" },
        { description: "Core", code: "142" },
        { description: "Cover", code: "143" },
        { description: "Cradle", code: "144" },
        { description: "Crate", code: "145" },
        { description: "Crate, beer", code: "146" },
        { description: "Crate, bulk, cardboard", code: "147" },
        { description: "Crate, bulk, plastic", code: "148" },
        { description: "Crate, bulk, wooden", code: "149" },
        { description: "Crate, framed", code: "150" },
        { description: "Crate, fruit", code: "151" },
        { description: "Crate, milk", code: "152" },
        { description: "Crate, multiple layer, cardboard", code: "153" },
        { description: "Crate, multiple layer, plastic", code: "154" },
        { description: "Crate, multiple layer, wooden", code: "155" },
        { description: "Crate, shallow", code: "156" },
        { description: "Creel", code: "157" },
        { description: "Cube", code: "158" },
        { description: "Cup", code: "159" },
        { description: "Cylinder", code: "160" },
        { description: "Demijohn,non-protected", code: "161" },
        { description: "Demijohn,protected", code: "162" },
        { description: "Dispenser", code: "163" },
        { description: "Double-length Rack", code: "164" },
        { description: "Double-length Skid", code: "165" },
        { description: "Double-length Tote Bin", code: "166" },
        { description: "Drum", code: "167" },
        { description: "Drum, aluminium", code: "168" },
        { description: "Drum, aluminium, non-removable head", code: "169" },
        { description: "Drum, aluminium, removable head", code: "170" },
        { description: "Drum, fibre", code: "171" },
        { description: "Drum, iron", code: "172" },
        { description: "Drum, plastic", code: "173" },
        { description: "Drum, plastic, non-removable head", code: "174" },
        { description: "Drum, plastic, removable head", code: "175" },
        { description: "Drum, plywood", code: "176" },
        { description: "Drum, steel", code: "177" },
        { description: "Drum, steel, non-removable head", code: "178" },
        { description: "Jerrican,plstc,no remov head (DG)", code: "481" },
        { description: "Drum, steel, removable head", code: "179" },
        { description: "Dry Bulk", code: "180" },
        { description: "Duffle Bag", code: "181" },
        { description: "Engine Container", code: "182" },
        { description: "Envelope", code: "183" },
        { description: "Filmpack", code: "184" },
        { description: "Firkin", code: "185" },
        { description: "Flask", code: "186" },
        { description: "Flo-bin", code: "187" },
        { description: "Foodtainer", code: "188" },
        { description: "Footlocker", code: "189" },
        { description: "Forward Reel", code: "190" },
        { description: "Frame", code: "191" },
        { description: "Garments on Hangers", code: "192" },
        { description: "Girder", code: "193" },
        { description: "Girders,inbundle/bunch/truss", code: "194" },
        { description: "Half-standard Rack", code: "195" },
        { description: "Half-Standard Tote Bin", code: "196" },
        { description: "Hamper", code: "197" },
        { description: "Heads of Beef", code: "198" },
        { description: "Hogshead", code: "199" },
        { description: "Hopper Truck", code: "200" },
        { description: "Household Goods Containers, Wood", code: "201" },
        { description: "Ingot", code: "202" },
        { description: "Ingots,inbundle/bunch/truss", code: "203" },
        { description: "Intermediate bulk container", code: "204" },
        { description: "Intermediate bulk ctr,alum", code: "205" },
        { description: "Intermediate bulk ctr,alum,liquid", code: "206" },
        { description: "Int bulk ctr,alum,liqu,press>10", code: "207" },
        { description: "Int bulk ctr,compos,flex plstc,liq", code: "208" },
        { description: "Int bulk ctr,compos,flex plstc,pres", code: "209" },
        { description: "Int bulk ctr,compos,flex plstc,sol", code: "210" },
        { description: "Int bulk ctr,compos,rigid plstc,liq", code: "211" },
        { description: "Int bulk ctr,compos,rigd plstc,pres", code: "212" },
        { description: "Int bulk ctr,compos,rigd plstc,sol", code: "213" },
        { description: "Intermediate bulk container, metal", code: "214" },
        { description: "Int bulk ctr,metal,liquid", code: "215" },
        { description: "Int bulk ctr,metal,press 10kpa", code: "216" },
        { description: "Int bulk ctr,nat wood,inner liner", code: "217" },
        { description: "Int bulk ctr,paper,multiwall", code: "218" },
        { description: "Int bulk ctr,papr,multiwall,H2O res", code: "219" },
        { description: "Int bulk ctr,plstc film", code: "220" },
        { description: "Int bulk ctr,plywood,inner liner", code: "221" },
        { description: "Int bulk ctr,recnst wood,inn liner", code: "222" },
        { description: "Int Bulk ctr,rigid plstc,stand,liq", code: "223" },
        { description: "Int Bulk ctr,rigid plstc,stand,pres", code: "224" },
        { description: "Int bulk ctr,rigid plstc,stand,sol", code: "225" },
        { description: "Int Bulk ctr,rig plstc,stru eq,liq", code: "226" },
        { description: "Int Bulk ctr,rig plstc,stru eq,pres", code: "227" },
        { description: "Int Bulk ctr,rig plstc,stru eq,sol", code: "228" },
        { description: "Intermediate bulk container, steel", code: "229" },
        { description: "Int Bulk ctr,steel,liquid", code: "230" },
        { description: "Int Bulk ctr,steel,press>10kpa", code: "231" },
        { description: "Int Bulk ctr,text,no out coat/liner", code: "232" },
        { description: "Int Bulk ctr,text,coated", code: "233" },
        { description: "Int Bulk ctr,text,coated and liner", code: "234" },
        { description: "Int Bulk ctr,text,liner", code: "235" },
        { description: "Int Bulk ctr,woven plstc,coated", code: "236" },
        { description: "Int Bulk ctr,woven plstc,coated/lin", code: "237" },
        { description: "Int Bulk ctr,woven plstc w/ liner", code: "238" },
        { description: "IntBulk ctr,wov plstc,w/o coat/lin", code: "239" },
        {
          description: "Intermodal Trailer/Container Load (Rail)",
          code: "240",
        },
        { description: "Jar", code: "241" },
        { description: "Jerrican, plastic", code: "242" },
        { description: "Jerrican,plstc,no remov head", code: "243" },
        { description: "Jerrican, plastic, removable head", code: "244" },
        { description: "Jerrican, steel", code: "245" },
        { description: "Jerrican, steel, non-removable head", code: "246" },
        { description: "Jerrican, steel, removable head", code: "247" },
        { description: "Jerrican,cylindrical", code: "248" },
        { description: "Jerrican,rectangular", code: "249" },
        { description: "Jug", code: "250" },
        { description: "Jutebag", code: "251" },
        { description: "Keg", code: "252" },
        { description: "Kit", code: "253" },
        { description: "Knockdown Rack", code: "254" },
        { description: "Knockdown Tote Bin", code: "255" },
        { description: "Lift Van", code: "256" },
        { description: "Lifts", code: "257" },
        { description: "Liner Bag Dry", code: "258" },
        { description: "Liner Bag Liquid", code: "259" },
        { description: "Liquid Bulk", code: "260" },
        { description: "Log", code: "261" },
        { description: "Logs,inbundle/bunch/truss", code: "262" },
        { description: "Loose", code: "263" },
        { description: "Lot", code: "264" },
        { description: "Lug", code: "265" },
        { description: "Mat", code: "266" },
        { description: "Matchbox", code: "267" },
        { description: "MILVAN - Military Van", code: "268" },
        { description: "Mixed Type Pack", code: "269" },
        { description: "MSCVAN - Military Sealift Command Van", code: "270" },
        { description: "Multiplybag", code: "271" },
        { description: "Multi-Roll Pack", code: "272" },
        {
          description: "Multiwall Container Secured to Warehouse Pallet",
          code: "273",
        },
        { description: "Mutually defined", code: "274" },
        { description: "Nest", code: "275" },
        { description: "Net", code: "276" },
        { description: "Net, tube, plastic", code: "277" },
        { description: "Net, tube, textile", code: "278" },
        { description: "Noil", code: "279" },
        { description: "Not available", code: "280" },
        { description: "On Hanger or Rack in Boxes", code: "281" },
        { description: "On Own Wheel", code: "282" },
        { description: "Overwrap", code: "283" },
        { description: "Package", code: "284" },
        { description: "Package, display, cardboard", code: "285" },
        { description: "Package, display, metal", code: "286" },
        { description: "Package, display, plastic", code: "287" },
        { description: "Package, display, wooden", code: "288" },
        { description: "Package, flow", code: "289" },
        { description: "Package, paper wrapped", code: "290" },
        { description: "Package, show", code: "291" },
        { description: "Packed - not otherwise specified", code: "292" },
        { description: "Packet", code: "293" },
        { description: "Pail", code: "294" },
        { description: "Pallet", code: "295" },
        { description: "Pallet, box", code: "296" },
        { description: "Pallet,modul,collar 80cm*100cm", code: "297" },
        { description: "Pallet,modul,collar 80cm*120cm", code: "298" },
        { description: "Parcel", code: "299" },
        { description: "Pieces", code: "300" },
        { description: "Pims", code: "301" },
        { description: "Pipe", code: "302" },
        { description: "Pipe Rack", code: "303" },
        { description: "Pipeline", code: "304" },
        { description: "Pipes, in bundle/bunch/truss", code: "305" },
        { description: "Pitcher", code: "306" },
        { description: "Plank", code: "307" },
        { description: "Planks,inbundle/bunch/truss", code: "308" },
        { description: "Plate", code: "309" },
        { description: "Plates,inbundle/bunch/truss", code: "310" },
        { description: "Platform", code: "311" },
        { description: "Pot", code: "312" },
        { description: "Pouch", code: "313" },
        { description: "Private Vehicle", code: "314" },
        { description: "Quarter of Beef", code: "315" },
        { description: "Rack", code: "316" },
        { description: "Rack, clothing hanger", code: "317" },
        { description: "Rail (Semiconductor)", code: "318" },
        { description: "Receptacle, glass", code: "319" },
        { description: "Receptacle, metal", code: "320" },
        { description: "Receptacle, plastic", code: "321" },
        { description: "Receptacle, plastic wrapped", code: "322" },
        { description: "Rednet", code: "323" },
        { description: "Reel", code: "324" },
        { description: "Reverse Reel", code: "325" },
        { description: "Ring", code: "326" },
        { description: "Rod", code: "327" },
        { description: "Rods,inbundle/bunch/truss", code: "328" },
        { description: "Roll", code: "329" },
        { description: "Sachet", code: "330" },
        { description: "Sack", code: "331" },
        { description: "Sack, multi-wall", code: "332" },
        { description: "Sea-chest", code: "333" },
        { description: "SEAVAN - Sea Van", code: "334" },
        { description: "Set", code: "335" },
        { description: "Sheet", code: "336" },
        { description: "Sheet, plastic wrapping", code: "337" },
        { description: "Sheetmetal", code: "338" },
        { description: "Sheets,inbundle/bunch/truss", code: "339" },
        { description: "Shook", code: "340" },
        { description: "Shrinkwrapped", code: "341" },
        { description: "Side of Beef", code: "342" },
        { description: "Skid", code: "343" },
        { description: "Skid, elevating or lift truck", code: "344" },
        { description: "Sleeve", code: "345" },
        { description: "Slip Sheet", code: "346" },
        { description: "Spin Cylinders", code: "347" },
        { description: "Spindle", code: "348" },
        { description: "Spool", code: "349" },
        { description: "Suitcase", code: "350" },
        { description: "Tank", code: "351" },
        { description: "Tank Car", code: "352" },
        { description: "Tank Truck", code: "353" },
        { description: "Tank,cylindrical", code: "354" },
        { description: "Tank,rectangular", code: "355" },
        { description: "Tea-chest", code: "356" },
        { description: "Tierce", code: "357" },
        { description: "Tin", code: "358" },
        { description: "Tote Bin", code: "359" },
        { description: "Tote Can", code: "360" },
        { description: "Tray", code: "361" },
        { description: "Tray, one layer no cover, cardboard", code: "362" },
        { description: "Tray, one layer no cover, plastic", code: "363" },
        { description: "Tray,1 layer no cover,polystyrene", code: "364" },
        { description: "Tray, one layer no cover, wooden", code: "365" },
        { description: "Tray,2 layer no cover,cardbrd", code: "366" },
        { description: "Tray,2 layer no cover,plstc tray", code: "367" },
        { description: "Tray, two layers no cover, wooden", code: "368" },
        { description: "Triwall Box", code: "369" },
        { description: "Truck", code: "370" },
        { description: "Trunk", code: "371" },
        { description: "Trunk and Chest", code: "372" },
        { description: "Trunk, Salesmen Sample", code: "373" },
        { description: "Truss", code: "374" },
        { description: "Tub", code: "375" },
        { description: "Tube", code: "376" },
        { description: "Tube, with nozzle", code: "377" },
        { description: "Tube,collapsible", code: "378" },
        { description: "Tubes,inbundle/bunch/truss", code: "379" },
        { description: "Tun", code: "380" },
        { description: "Unit", code: "381" },
        { description: "Unpacked", code: "382" },
        { description: "Vacuum-packed", code: "383" },
        { description: "Van Pack", code: "384" },
        { description: "Vat", code: "385" },
        { description: "Vehicles", code: "386" },
        { description: "Vial", code: "387" },
        { description: "Wheeled Carrier", code: "388" },
        { description: "Wickerbottle", code: "389" },
        { description: "Wrapped", code: "390" },
        { description: "Drum, wooden", code: "391" },
        { description: "Bag, super bulk", code: "392" },
        { description: "Int Bulk ctr,rigid plstc", code: "393" },
        { description: "Receptacle, fibre", code: "394" },
        { description: "Receptacle, paper", code: "395" },
        { description: "Receptacle, wooden", code: "396" },
        { description: "Pallet,modul,collar 80cm*60cm", code: "397" },
        { description: "Pallet, shrinkwrapped", code: "398" },
        { description: "Pallet, 100cms * 110cms", code: "399" },
        { description: "Clamshell", code: "400" },
        { description: "Cone", code: "401" },
        { description: "Capsule", code: "402" },
        { description: "Card", code: "403" },
        { description: "Cartridge", code: "404" },
        { description: "Bag, flexible container", code: "405" },
        { description: "Pack,cardboard,w/bottle grip holes", code: "406" },
        { description: "Unpacked unpackaged, single unit", code: "407" },
        { description: "Unpackd multple units", code: "408" },
        { description: "Pen", code: "409" },
        { description: "Punnet", code: "410" },
        { description: "Slab", code: "411" },
        { description: "Case, steel", code: "412" },
        { description: "Envelope, steel", code: "413" },
        { description: "Sleeve", code: "414" },
        { description: "Tierce", code: "415" },
        { description: "Tub, with lid", code: "416" },
        { description: "Uncaged", code: "417" },
        { description: "Vanpack", code: "418" },
        { description: "Intermediate bulk ctr,composite", code: "419" },
        { description: "Int bulk ctr,fibreboard", code: "420" },
        { description: "Int bulk ctr,flex", code: "421" },
        { description: "Int bulk ctr,metal,other no steel", code: "422" },
        { description: "Int bulk ctr,natural wood", code: "423" },
        { description: "Int bulk ctr,plywood", code: "424" },
        { description: "Int bulk ctr,reconstituted wood", code: "425" },
        { description: "UNIT", code: "430" },
        { description: "Cart, flatbed", code: "426" },
        { description: "Tray,w/ horizont stacked flat itms", code: "427" },
        { description: "Tray,rigd,lid stack-CENTS14482:2002", code: "428" },
        { description: "Vehicle", code: "429" },
        { description: "Unit", code: "431" },
        { description: "Octabin", code: "433" },
        { description: "Drum, plastic, non-removable head (DG)", code: "478" },
        { description: "Drum, plastic, removable head (DG)", code: "479" },
        { description: "Bag,paper,multiwall,H2O resis (DG)", code: "482" },
        { description: "Bag, gunny", code: "441" },
        { description: "Bag, jumbo", code: "442" },
        { description: "Bag, polybag", code: "443" },
        { description: "Bag, tote", code: "444" },
        { description: "Ball", code: "445" },
        { description: "Belt", code: "446" },
        { description: "Bulk, scrap metal", code: "447" },
        { description: "Bundle, wooden", code: "448" },
        { description: "Case, car", code: "449" },
        { description: "Case, wooden", code: "450" },
        { description: "Container, flexible", code: "451" },
        { description: "Container, gallon", code: "452" },
        { description: "Container, metal", code: "453" },
        { description: "Container, outer", code: "454" },
        { description: "Crate, metal", code: "455" },
        { description: "Crate, wooden", code: "456" },
        { description: "Flexibag", code: "457" },
        { description: "Hanger", code: "459" },
        { description: "Luggage", code: "462" },
        { description: "Pallet, AS 4068-1993", code: "463" },
        { description: "Pallet, CHEP 100 cmx120 cm", code: "464" },
        { description: "Pallet, CHEP 40cmx60 cm", code: "465" },
        { description: "Pallet, CHEP 80 cmx120 cm", code: "466" },
        { description: "Pallet, ISO T11", code: "467" },
        { description: "Pallet, triwall", code: "468" },
        { description: "Pallet, wooden", code: "469" },
        { description: "Pan", code: "470" },
        { description: "Piece", code: "471" },
        { description: "Platform,unspecif weigh/dimens", code: "472" },
        { description: "Tablet", code: "473" },
        { description: "Tank container, generic", code: "474" },
        { description: "Tyre", code: "475" },
        { description: "Drum, steel, removable head (DG)", code: "477" },
        { description: "Container, NOS", code: "483" },
        { description: "BAGS900", code: "484" },
        {
          description: "Jerrican, steel, non-removable head (DG)",
          code: "480",
        },
        { description: "Flexitank", code: "432" },
        { description: "Drum, steel, non-removable head (DG)", code: "476" },
      ],
      partyTypes: [
        { label: "Shipper", value: "3" },
        { label: "Consignee", value: "4" },
        { label: "Forwarder", value: "5" },
        { label: "Notify Party", value: "6" },
        { label: "Additional Notify Party 1", value: "7" },
        { label: "Exporter", value: "8" },
        { label: "Goods Owner", value: "9" },
        { label: "Additional Notify Party 2", value: "11" },
        { label: "Ship to", value: "13" },
        { label: "Contract Party", value: "17" },
        { label: "Freight Payer", value: "18" },
        { label: "Manufacturer/Supplier", value: "21" },
        { label: "Consolidator/Stuffer", value: "22" },
        { label: "Importer", value: "23" },
      ],
      productTypes: ["FRESH", "ORGANIC", "FROZEN", "DRIED", "PROCESSED"],
      cityLocations: cities,
      CHARGE_METHODS,
      INSTRUCTION_METHODS,
      FILING_OPTIONS,
      REEFER_TYPE,
    };
  },

  computed: {
    entity() {
      if (
        !this.resultData ||
        !this.resultData.shippingInstruction ||
        Object.keys(this.resultData.shippingInstruction.data).length === 0
      )
        return null;

      return this.resultData.shippingInstruction.data;
    },

    validData() {
      return this.resultData && this.entity;
    },

    clauseList() {
      return [
        {
          title: "Age of Vessel",
          checked: false,
        },
        {
          title: "Caribbean Basin Initiative (CBI)",
          checked: false,
        },
        {
          title:
            "Carrier Reserves the Right to Place Container(s) in Heated Warehouse at a Set Cost",
          checked: false,
        },
        {
          title: "Certification Statements",
          checked: false,
        },
        {
          title: "Container Packing Certificate",
          checked: false,
        },
        {
          title: "Container Safety Act",
          checked: false,
        },
        {
          title: "Container(s) Sealed by Shipper",
          checked: false,
        },
        {
          title: "Cool Cargo",
          checked: false,
        },
        {
          title: "Custom",
          checked: false,
        },
        {
          title: "Delivery Order Liability Clause",
          checked: false,
        },
        {
          title: "Destination Control Statements",
          checked: false,
        },
        {
          title: "Destination Country",
          checked: false,
        },
        {
          title: "Disclaimer",
          checked: false,
        },
        {
          title: "Emergency Response Statement",
          checked: false,
        },
      ];
    },

    isSingleCargo() {
      if (!this.entity.ShipmentInstruction.IsSingleCommodity) return false;

      if (this.entity.ShipmentInstruction.IsSingleCommodity === "true")
        return true;

      return false;
    },

    jsonViewportHeight() {
      return this.jsonDebugHeight;
    },

    canSubmit() {
      const isSystemAdmin = [1, 2].includes(this.$store.state.user.tagId);
      if (isSystemAdmin) return true;

      const hardcodedUserIds = SI_TEST_USERS_IDS; //TODO - Remove, only for testing
      if (hardcodedUserIds.includes(this.$store.state.user.id)) return true;

      return false;
    },

    filteredUsers() {
      let result = this.orgUsers;
      if (this.partnerEmailSearch) {
        result = result.filter(
          (x) =>
            x.user.firstname
              .toLowerCase()
              .includes(this.partnerEmailSearch.toLowerCase()) ||
            x.user.surname
              .toLowerCase()
              .includes(this.partnerEmailSearch.toLowerCase()) ||
            x.user.emailAddress
              .toLowerCase()
              .includes(this.partnerEmailSearch.toLowerCase())
        );
      }
      result.sort((a, b) => a.user.firstname.localeCompare(b.user.firstname));
      return result;
    },

    selectedPartnerEmailList() {
      return this.selectedPartnerEmails.join(",");
    },
  },

  methods: {
    async initData() {
      await this.loadShippingInstruction();
      this.getOrganisationUsers();
      this.setPartnerEmails();
      if (
        !this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue
          .GeographyAreaId
      ) {
        this.setPlaceOfIssue(this.cityLocations[0].value);
      }
    },
    closeReeferSettings(container) {
      if (
        container.ContainerPopupCollections &&
        container.ContainerPopupCollections.ContainerReeferPopup
      ) {
        container.ReeferAirFlow =
          container.ContainerPopupCollections.ContainerReeferPopup.ReeferAirFlow;
        container.ReeferTemperature =
          container.ContainerPopupCollections.ContainerReeferPopup.ReeferTemperature;
        container.ReeferTemperatureUomDesc =
          container.ContainerPopupCollections.ContainerReeferPopup.ReeferTemperatureUomDesc;
        container.ReeferAirFlowUomDesc =
          container.ContainerPopupCollections.ContainerReeferPopup.ReeferAirFlowUomDesc;
      }
      this.reeferSettingsDialog = false;
    },
    async loadShippingInstruction() {
      try {
        this.loadingData = true;

        let query = null;

        if (this.actionInput) {
          const params = {};
          if (this.actionInput.task) {
            params.taskId = this.actionInput.task.id;
            params.shipmentDocumentTypeId =
              this.actionInput.task.shipmentDocumentTypeId;
            params.parentShipmentDocumentId =
              this.actionInput.task.parentShipmentDocumentId;
          }

          query = params;
        }
        const data = await this.$API.getShippingInstructionPreview(
          this.shipmentId,
          SI_INTEGRATION_CODES.INTTRA,
          query
        );
        this.resultData = data;
      } catch (e) {
        this.$message.error(e.message || e);
      } finally {
        this.loadingData = false;
      }
    },

    setPartnerEmails() {
      const existingEmails =
        this.entity.ShipmentInstruction.SICompanies.Requestor.CompanyContacts
          .CompanyContact_1.CompanyCommunications.Email_1.CommunicationDetails;

      if (existingEmails) {
        const emails = existingEmails.split(",");
        this.selectedPartnerEmails = Array.from(
          new Set([...emails, ...this.selectedPartnerEmails])
        );
      }
    },

    async getOrganisationUsers() {
      try {
        this.loadingOrgUsers = true;
        this.orgUsers = await this.$API.getOrganisationUsers();
      } catch (e) {
        this.$message.error(e.message || e);
      } finally {
        this.loadingOrgUsers = false;
      }
    },
    getPaymentInstructions(charges) {
    if(!charges || charges.length === 0) return {};

    let paymentInstructions = {};
    for(const [index, charge] of charges.entries()) {
        paymentInstructions[`IndividualCharges_${index}`] = charge;
    }

    return paymentInstructions;
},
    async submitDraftShippingInstruction() {
      try {
        this.loadingData = true;

        const inputs = {
          partnerEmails: this.selectedPartnerEmailList,
        };

        const placeOfIssue = this.getPlaceOfIssue();
        if (placeOfIssue) inputs.blPlaceOfIssue = placeOfIssue;

        this.entity.ShipmentInstruction.SIComments =
          this.entity.ShipmentInstruction.BLRemarks;
        this.entity.ShipmentInstruction.PaymentInstructions = this.getPaymentInstructions(this.entity.chargesArray);
        if (this.selectedPartnerEmailList) {
          let emails = this.selectedPartnerEmailList.split(",");
          if (emails.length > 6) {
            const sliceAmount = 6;
            emails = emails.slice(0, sliceAmount);
          }
          this.entity.ShipmentInstruction.SICompanies.MessageRecipient = {
            // From who the SI is sent to
            CompanyContacts: {
              CompanyContact_1: {
                CompanyCommunications: {
                  Email_1: {
                    CommunicationDetails: emails.join(","),
                  },
                },
              },
            },
          };
        }
        // if(this.actionType === SI_FORM_ACTION_TYPES.EMIT) {
        this.$emit("action", {
          actionInput: this.actionInput,
          actionOutput: {
            shipmentReference: this.shipmentId,
            userId: this.$store.state.user.id,
            inputs,
            shipmentInstruction: this.resultData,
          },
        });
        // } else if(this.actionType === SI_FORM_ACTION_TYPES.INTERNAL) {
        //   const data = await this.$API.submitShippingInstructionDraft(
        //     this.shipmentId,
        //     SI_INTEGRATION_CODES.INTTRA,
        //     {
        //       inputs
        //     }
        //   );

        //   this.resultData.submissionResult = data;
        // }
      } catch (e) {
        this.$message.error(e.message || e);
      } finally {
        this.loadingData = false;
        this.submitDraftDialog = false;
      }
    },

    getPlaceOfIssue() {
      const obj = {};

      if (
        this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.LocationCity
      ) {
        obj.locationCity =
          this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.LocationCity;
      }

      if (
        this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.PrintOnBLAs
      ) {
        obj.printOnBLAs =
          this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.PrintOnBLAs;
      }

      if (
        this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.PrintOnBLAs
      ) {
        obj.geographyAreaId =
          this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.GeographyAreaId;
      }

      return Object.keys(obj).length > 0 ? obj : null;
    },

    setPlaceOfIssue(geoValue) {
      if (!geoValue) return;
      const [, country, countryCity] = geoValue.split(":");
      const [city] = countryCity.split(",");

      this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.LocationCity =
        city;
      this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.PrintOnBLAs = `${city}, ${country}`;
      this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.GeographyAreaId =
        geoValue;
      this.entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.BLPlaceOfIssueHidden =
        geoValue;
    },

    toggleParticularsFullscreen() {
      if (this.particularsFullscreen) {
        document.exitFullscreen();
      } else {
        const elem = document.getElementById("particularsWrapper");
        elem.requestFullscreen();
      }
    },

    viewDialog(container, index, dialogRef) {
      this.selectedContainer = container;
      this.selectedContainerIndex = index;

      this[dialogRef] = true;
    },

    getCargoHazmatItems(container) {
      //TODO - Create hazmat item list
      return [];
    },

    viewDebugDialog() {
      this.setJsonDebugHeight();
      this.debugDialog = true;
    },

    setJsonDebugHeight() {
      this.jsonDebugHeight = document.body.clientHeight - 110;
    },

    fullscreenHandler() {
      this.particularsFullscreen = Boolean(document.fullscreenElement);
    },

    getHSCode(containerLineItem) {
      return (
        containerLineItem.LineItemAttrs?.LineItemAttr_2?.LineItemAttrValue || ""
      );
    },

    showPartnerEmails() {
      this.getOrganisationUsers();
      this.partnerUserDialog = true;
    },

    addPartnerEmail(email) {
      const user = this.selectedPartnerEmails.indexOf(email);
      if (user == -1) {
        if (this.selectedPartnerEmails.length >= 6) return;
        this.selectedPartnerEmails.push(email);
      } else {
        this.selectedPartnerEmails.splice(user, 1);
      }
    },
    addContactDetail(type) {
      let item =
        this.entity.ShipmentInstruction.SICompanies[type].CompanyContacts
          .CompanyContact_1;
      if (!item) {
        this.contactDetail = {
          type,
          name: "",
          emailAddress: "",
          phoneNumber: "",
        };
        this.contactDetailDialog = true;
      } else {
        this.contactDetail = {
          type,
          name: item.ContactName,
          emailAddress: item.CompanyCommunications.Email
            ? item.CompanyCommunications.Email.CommunicationDetails
            : "",
          phoneNumber: item.CompanyCommunications.Telephone
            ? item.CompanyCommunications.Telephone.CommunicationDetails
            : "",
        };
        this.contactDetailDialog = true;
      }
      // "CompanyContacts": {
      //               "CompanyContact_1": {
      //                   "ContactTypeCode": "1",
      //                   "CompanyCommunications": {
      //                       "Email": {
      //                           "CompanyCommunicationId": "",
      //                           "CommunicationTypeCode": "3",
      //                           "CommunicationTypeDescription": "Email",
      //                           "CommunicationDetails": "yvette@lclog.com"
      //                       },
      //                       "Telephone": {
      //                           "CompanyCommunicationId": "",
      //                           "CommunicationTypeCode": "1",
      //                           "CommunicationTypeDescription": "Telephone",
      //                           "CommunicationDetails": "021 9436760"
      //                       }
      //                   },
      //                   "CompanyContactId": "",
      //                   "ContactName": "Yvette Kruger",
      //                   "ContactTypeDescription": "Information Contact"
      //               }
      //           },
    },
    setContactDetails() {
      // let item = this.entity.ShipmentInstruction.SICompanies[this.contactDetail.type].CompanyContacts.CompanyContact_1;
      // if(!item){
      this.entity.ShipmentInstruction.SICompanies[
        this.contactDetail.type
      ].CompanyContacts.CompanyContact_1 = {
        ContactTypeCode: "1",
        CompanyCommunications: {
          Email: {
            CompanyCommunicationId: "",
            CommunicationTypeCode: "3",
            CommunicationTypeDescription: "Email",
            CommunicationDetails: this.contactDetail.emailAddress,
          },
          Telephone: {
            CompanyCommunicationId: "",
            CommunicationTypeCode: "1",
            CommunicationTypeDescription: "Telephone",
            CommunicationDetails: this.contactDetail.phoneNumber,
          },
        },
        CompanyContactId: "",
        ContactName: this.contactDetail.name,
        ContactTypeDescription: "Information Contact",
      };
      // } else {
      //   item.ContactName = this.contactDetail.name;
      //   item.CompanyCommunications.Email.CommunicationDetails = this.contactDetail.emailAddress;
      //   item.CompanyCommunications.Telephone.CommunicationDetails = this.contactDetail.phoneNumber;
      // }
      this.contactDetailDialog = false;
    },
    setCompanyDocuments(type) {
      if (type == "SEAWAY") {
        this.entity.ShipmentInstruction.SICompanies.Requestor.CompanyDocuments =
          {
            SeaWaybillDocumentFreighted: {
              NumberOfDocuments: "",
            },
            SeaWaybillDocumentNonFreighted: {
              NumberOfDocuments: "",
            },
          };
      } else if (type == "ORIGINAL") {
        this.entity.ShipmentInstruction.SICompanies.Requestor.CompanyDocuments =
          {
            OriginalDocumentFreighted: {
              NumberOfDocuments: "",
            },
            OriginalDocumentNonFreighted: {
              NumberOfDocuments: "",
            },
            NonNegotiableDocumentFreighted: {
              NumberOfDocuments: "",
            },
            NonNegotiableDocumentNonFreighted: {
              NumberOfDocuments: "",
            },
          };
      } else if (type == "HOUSE_BILL") {
        this.entity.ShipmentInstruction.SICompanies.Requestor.CompanyDocuments =
          {
            HouseBillOfLading: {
              MasterNumber: "",
            },
          };
      }
    },
    setPartyTypeCode(item) {
      let find = this.partyTypes.find((x) => x.label === item.PartyName);
      if (find) {
        item.PartyTypeCode = find.value;
      }
    },
    updateContainerCargoDescription(container) {
      for (let i = 0; i < container.ContainerLineItems.length; i++) {
        if (container.productType) {
          container.ContainerLineItems[i].CargoDescription =
            container.productType +
            " " +
            container.ContainerLineItems[i].CargoDescription;
        } else {
          // remove product type from cargo description
          for (let j = 0; j < this.productTypes.length; j++) {
            container.ContainerLineItems[i].CargoDescription =
              container.ContainerLineItems[i].CargoDescription.replace(
                this.productTypes[j] + " ",
                ""
              );
          }
        }
      }
    },
    updateMoveTypeDescription() {
      let findMoveTypeCode = this.moveTypes.find(
        (x) => x.code === this.entity.ShipmentInstruction.MoveTypeCode
      );
      if (findMoveTypeCode) {
        this.entity.ShipmentInstruction.MoveTypeDesc =
          findMoveTypeCode.description;
      }
    },
    updatePackageTypeCode(containerLineItem) {
      let findCode = this.packageTypes.find(
        (x) => x.code === containerLineItem.PackageTypeCode
      );
      if (findCode) {
        containerLineItem.PackageTypeDescription = findCode.description;
      }
    },
    updateControl(type) {
      let containers = this.entity.ShipmentInstruction.Containers;
      let allLineItems = [].concat.apply(
        [],
        containers.map((x) => x.ContainerLineItems)
      );
      if (type == "TotalWeight") {
        this.entity.ShipmentInstruction.ShipmentSummary.TotalWeight =
          allLineItems
            .map((x) => parseFloat(x.GrossCargoWeight))
            .reduce((a, b) => a + b, 0)
            .toFixed(3)
            .toString();
      } else if (type == "TotalVolume") {
        this.entity.ShipmentInstruction.ShipmentSummary.TotalVolume =
          allLineItems
            .map((x) => x.GrossCargoVolume)
            .reduce((a, b) => a + b, 0)
            .toFixed(3)
            .toString();
      } else if (type == "TotalPackageCount") {
        this.entity.ShipmentInstruction.ShipmentSummary.TotalPackageCount =
          allLineItems
            .map((x) => parseInt(x.PackageCount))
            .reduce((a, b) => a + b, 0);
      }
    },
  },

  mounted() {
    this.initData();

    document.addEventListener("fullscreenchange", this.fullscreenHandler);
  },

  watch: {
    "$vuetify.breakpoint.width"() {
      this.setJsonDebugHeight();
    },

    "entity.ShipmentInstruction.SILocations.BLPlaceOfIssue.GeographyAreaId"(
      newVal
    ) {
      this.setPlaceOfIssue(newVal);
    },
  },

  destroyed() {
    document.removeEventListener("fullscreenchange", this.fullscreenHandler);
  },
};
</script>

<style>
</style>