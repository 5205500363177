<template>
  <div>
    <v-card v-if="form && form.name" flat>
      <v-card-title>{{ form.name }} <v-spacer></v-spacer><v-btn text @click="$emit('close')">X</v-btn></v-card-title>
      <v-card-subtitle style="font-size: 12px" v-if="form.description">{{
        form.description
      }}</v-card-subtitle>
      <v-card-text style="max-height: 50vh; overflow-y: auto">
        <v-row class="mt-2">
          <v-col cols="12" sm="6" class="my-0 py-0" v-for="field in form.formItems" :key="field.id">
            <v-text-field v-if="field.type == 'text'" @change="formKey++" v-model="formResponse[field.value]"
              :label="`${field.name}${field.required ? '*' : ''}`" outlined dense>
              <template v-slot:append>
                <v-tooltip top v-if="field.description">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">help</v-icon>
                  </template>
                  <span style="font-size: 12px">{{ field.description }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <v-textarea v-else-if="field.type == 'textarea'" @change="formKey++" v-model="formResponse[field.value]"
              :label="`${field.name}${field.required ? '*' : ''}`" outlined dense auto-grow rows="3">
              <template v-slot:append>
                <v-tooltip top v-if="field.description">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">help</v-icon>
                  </template>
                  <span style="font-size: 12px">{{ field.description }}</span>
                </v-tooltip>
              </template>
            </v-textarea>
            <v-checkbox v-else-if="field.type == 'checkbox'" v-model="formResponse[field.value]"
              :label="`${field.name}${field.required ? '*' : ''}`" outlined dense></v-checkbox>
            <v-autocomplete v-else-if="field.type == 'selection'" v-model="formResponse[field.value]"
              :label="`${field.name}${field.required ? '*' : ''}`" outlined dense :items="field.formItemOptions"
              item-text="name" item-value="value" @change="formKey++">
              <template v-slot:append>
                <v-tooltip top v-if="field.description">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">help</v-icon>
                  </template>
                  <span style="font-size: 12px">{{ field.description }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions :key="formKey">
        <v-col class="text-center">
          <v-btn color="primary" :key="formKey" text @click="submitForm">Submit</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["form", "skipValidate"],
  data: () => ({
    formResponse: {},
    formKey: 1000
  }),
  watch: {
    // "form.id": {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {

    //     }
    //   },
    // },
  },
  computed: {
    validResponse() {
      let valid = true;
      if (!this.skipValidate) {
        this.form.formItems.forEach((field) => {
          if (field.required && !this.formResponse[field.value]) {
            console.log(field.name, this.formResponse[field.value])
            valid = false;
          }
        });
      }
      return valid;
    },
  },
  mounted() {
    this.formResponse = {};
    if (!this.form.formResponse) {
      this.form.formItems.forEach((field) => {
        if (field.type == "text" || field.type == "textarea" || field.type == "selection") {
          this.formResponse[field.value] = field.defaultValue ;
        
        } else if (field.type == "checkbox") {
          this.formResponse[field.value] = field.defaultFlag;
        }
      });
    } else {
      for (let i = 0; i < this.form.formItems.length; i++) {
        let field = this.form.formItems[i];
        if(typeof(this.form.formResponse) == 'string'){
          this.form.formResponse = JSON.parse(this.form.formResponse);
        }
        let find = this.form.formResponse.find(
          (x) => x.key == field.value
        );
        if (find) {
          this.formResponse[field.value] = find.value;
        } else {
          if (field.type == "text" || field.type == "textarea" || field.type == "selection") {
            this.formResponse[field.value] = field.defaultValue;
          } else if (field.type == "checkbox") {
            this.formResponse[field.value] = field.defaultFlag;
          }
        }
      }
    }
  },
  methods: {
    submitForm() {
      let result = [];
      for (let i = 0; i < this.form.formItems.length; i++) {
        let field = this.form.formItems[i];
        result.push({
          name: field.name,
          key: field.value,
          value: this.formResponse[field.value],
          valueName: field.formItemOptions
            ? field.formItemOptions.find(
              (option) => option.value == this.formResponse[field.value]
            )
              ? field.formItemOptions.find(
                (option) => option.value == this.formResponse[field.value]
              ).name
              : null
            : null,
        });
      }
      this.$emit("submit", result);
      this.$emit("close");
    },
  },
};
</script>
