<template>
  <v-dialog v-model="show" fullscreen>
    <v-card rounded="0" flat>
      <!-- <div v-if="dialogCloseControl" class="d-flex justify-end pa-2">
        <v-icon @click="close">close</v-icon>
      </div>  -->
      <div>
        <component :is="componentName" :shipmentId="shipmentId" :actionType="actionType" @close="close" :actionInput="actionInput" @action="action"></component>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import InttraShippingInstructionForm from '@/components/Integrations/ShippingInstructions/Inttra/InttraShippingInstructionForm.vue';
import { SI_INTEGRATION_CODES, SI_FORM_ACTION_TYPES } from '@/utils/constants'

const INTEGRATION_CODE_MAP = {
  [SI_INTEGRATION_CODES.INTTRA]: "InttraShippingInstructionForm"
}

export default {
  components: {
    InttraShippingInstructionForm
  },

  data() {
    return {
      show: false,
      shipmentId: "",
      intergrationCode: "",
      dialogCloseControl: true,
      actionType: SI_FORM_ACTION_TYPES.INTERNAL,
      actionInput: null
    }
  },

  computed: {
    componentName() {
      return INTEGRATION_CODE_MAP[this.intergrationCode];
    }
  },

  methods: {
    open({
        shipmentId = "",
        intergrationCode = "",
        dialogCloseControl = true,
        actionType = SI_FORM_ACTION_TYPES.INTERNAL,
        actionInput = null
    }) {
      this.shipmentId = shipmentId
      this.intergrationCode = intergrationCode
      this.dialogCloseControl = dialogCloseControl
      this.actionType = actionType,
      this.actionInput = actionInput

      this.show = true;
      this.$emit("opened");
    },

    close() {
      this.show = false;
      this.$emit("closed");
    },

    action(payload) {
      this.$emit("action", payload)
    }
  }
}
</script>

<style>

</style>